<template>
  <div class="menu">
    <ul class="meun-list" v-if="userinfo.identity!=2">
      <li class="meun-li" v-for="(item, index) in list" :key="index">
        <ul>
          <li @click="listTog(item)">
            <a-icon type="unordered-list" class="icon" />{{ item.title }}
          </li>
        </ul>
        <ul v-if="item.toteltog">
          <li
            v-for="(item, index) in item.subtitle"
            :key="index"
            :class="{ bj: itemnum == item.xh, meunLiLi: meunLiLiys }"
            @click="subIndex(item)"
          >
            {{ item.sub }}
          </li>
        </ul>
      </li>
    </ul>
    <ul class="meun-list" v-else>
      <li class="meun-li" v-for="(item, index) in lists" :key="index">
        <ul>
          <li @click="listTog(item)">
            <a-icon type="unordered-list" class="icon" />{{ item.title }}
          </li>
        </ul>
        <ul v-if="item.toteltog">
          <li
            v-for="(item, index) in item.subtitle"
            :key="index"
            :class="{ bj: itemnum == item.xh, meunLiLi: meunLiLiys }"
            @click="subIndex(item)"
          >
            {{ item.sub }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userinfo:"",
      meunLiLiys: true,
      itemnum: -1,
      list: [
        {
          title: "订单管理",
          toteltog: false,
          subtitle: [
            { sub: "行李寄存订单列表", xh: 1 },
            { sub: "行李转交订单列表", xh: 2 },
            { sub: "快递寄存订单列表", xh: 3 },
          ],
        },
        {
          title: "短信管理",
          toteltog: false,
          subtitle: [{ sub: "充值列表", xh: 4 }],
        },
        {
          title: "酒店二维码",
          toteltog: false,
          subtitle: [{ sub: "酒店二维码", xh: 5 }],
        },
        {
          title: "行李牌管理",
          toteltog: false,
          subtitle: [{ sub: "行李牌列表", xh: 6 }],
        },
        {
          title: "店员管理",
          toteltog: false,
          subtitle: [{ sub: "店员列表", xh: 7 }],
        },
      ],
      lists: [
        {
          title: "订单管理",
          toteltog: false,
          subtitle: [
            { sub: "行李寄存订单列表", xh: 1 },
            { sub: "行李转交订单列表", xh: 2 },
            { sub: "快递寄存订单列表", xh: 3 },
          ],
        },
        {
          title: "酒店二维码",
          toteltog: false,
          subtitle: [{ sub: "酒店二维码", xh: 5 }],
        },
        
      ],
    };
  },
  mounted(){
    this.userinfo=JSON.parse(sessionStorage.getItem("info")) 
    console.log(this.userinfo);
  },
  methods: {
    listTog(info) {
      info.toteltog = !info.toteltog;
    },
    subIndex(info) {
      this.itemnum = info.xh;
      if (info.sub == "行李寄存订单列表") {
        this.$router.push("/jdhome/jdjc");
      }
      if (info.sub == "行李转交订单列表") {
        this.$router.push("/jdhome/jdzj");
      }
      if (info.sub == "快递寄存订单列表") {
        this.$router.push("/jdhome/jdkd");
      }
      if (info.sub == "充值列表") {
        this.$router.push("/jdhome/jdmoney");
      }
      if (info.sub == "酒店二维码") {
        this.$router.push("/jdhome/jdewm");
      }
      if (info.sub == "行李牌列表") {
        this.$router.push("/jdhome/jdxllist");
      }
      if (info.sub == "店员列表") {
        this.$router.push("/jdhome/jdperson");
      }
    },
  },
};
</script>
<style scoped>
.menu {
  padding-top: 20px;
}
.meun-list {
  color: white;
}
.meun-li {
  line-height: 50px;
}
.meun-li:hover {
  cursor: pointer;
}
.meun-list .icon {
  padding: 0 10px;
  padding-left: 25px;
}
.meunLiLi {
  height: 50px;
  line-height: 50px;
  padding-left: 50px;
}
.meun-li-li:hover {
  background: #e6b666;
  cursor: pointer;
}
.bj {
  background: #e6b666;
}
</style>