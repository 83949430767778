<template>
  <div>
    <a-row class="top">
      <a-col :span="4" class="text"> 订单列表/客户信息录入 </a-col>
      <a-col :span="1" class="top-rrr">
        <a-button @click="back">返回</a-button>
      </a-col>
    </a-row>
    <div class="c">
      <div class="imgw">
        <a-icon
          type="check-circle"
          style="
            font-size: 150px;
            text-align: center;
            display: block;
            color: #e6b666;
          "
        />
      </div>
      <div class="s">{{ name }}</div>
      <div>
        <a-button type="danger" class="but" v-print="printObj">
          打印小票
        </a-button>
      </div>
    </div>
    <div class="xplists">
      <div class="xplist" id="printTest">
        <div class="ffff">
          <div class="s1">订单号:{{ dxlist.id }}</div>
          <div class="s1">
            取件码:{{ dxlist.qjCode || dxlist.infos.qjCode }}
          </div>
           <div class="s1" v-if=" dxlist.homeNum">
            房间号:{{ dxlist.homeNum || dxlist.infos.homeNum }}
          </div>
          <div class="s1" v-else>
            房间号:{{ dxlist.homeNum || ""}}
          </div>
          <div class="s1">
            寄存时间:{{ dxlist.createDate || dxlist.infos.createDate }}
          </div>
          <div class="s1">
            寄存酒店:{{ dxlist.hotelName || dxlist.infos.hotelName }}
          </div>
          <div class="s1">
            寄存手机号:{{ dxlist.phone || dxlist.infos.phone }}
          </div>
          <div id="qrCode" ref="qrCodeDiv"></div>
          <div class="s1">
          用户需知：
          宾客免费在本酒店存放行李，酒店仅负责谨慎看管，请宾客认真阅读下列内容，了解并接受后方可存放。
          物主声明，存放的行李中无现金、珠宝、证券等贵重物品，存放的物品无易燃、易碎、易腐、有毒等非法或危险物品。若物主虚假声明，酒店不承担任何责任。
          宾客同意所存放物品的最高价值不超过人民币1000元。在任何情况下酒店承担的责任最高不超过人民币1000元
          宾客行李最长存放时间为30天，在此期间由宾客或委托的第三人依据该《声明》自行领取并承担费用。如存放时间超过30天的，本酒店有权自行处理李，由此产生的损失即费用由物主自行承担。
          该《声明》为领取行李的唯一凭证，请宾客自行保管好，如丢失请在第一时间内向酒店行李部登记，否则任何人均可依据该《声明》领取行李或物品，由此导致的损失由宾客自行承担。
          Luggage can be stored for free,the hotel is only responsible for
          storage, please read the following content carefully, if you can
          accept it can be stored. The owner confirms and hereby, that the
          stored luggage items or articles do not contain any money, jewellery
          or other valuable items, nor any items in the form of
          fragile,perishable, toxic or dangerous products. To this effect, the
          hotel will not be held responsible for any liability in the case of
          false declarations given by the owner of such items. The guest hereby
          agrees to limit the value of the deposited items to RMB1000 or less.
          The hotel shall not bear any responsibility for any mishaps during
          storage, and in any event the total liability of the hotel shall be
          limited to a maximum amount of RMB1000. The maximum time for luggage
          storage is 30 days. The guest or the consignor shall collect the
          luggage as per the above declaration with their own expense. The hotel
          has the right to dispose the luggage that has been stored for more
          than 30 days, and the owner shall be responsible for the loss caused
          thereby. The hotel will release the stored luggage to any person only
          upon presentation of the storage claim tag. If you lose the luggage
          tag, please register at Hotel Concierge immediately, otherwise anyone
          claim luggage or articles with the tag, and the lose caused by this
          shall be responsible by the owner.
        </div>
        <div class="s1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      printObj: {
        id: "printTest", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      dxlist: {},
      name: "",
    };
  },
  created() {
    this.dxlist = this.$route.params.infos;
    console.log(this.dxlist);
    // this.dxlist.homeNum=this.$route.params.infos.homeNum
  },
  mounted() {
    this.name = this.$route.params.names;
    //生成二维码
    var that = this;
    var jstr = {
      createDate: that.dxlist,
      homeNum: that.hotelName,
      hotelName: that.hotelName,
      id: that.id,
      isCare: that.isCare,
      mark: that.mark,
      phone: that.phone,
      qjCode: that.qjCode,
      state: that.state,
      type: that.type,
      userName: that.userName,
    };
    console.log(that.dxlist);
    console.log("------------");
    let artt = JSON.stringify(jstr);
    console.log(JSON.stringify(jstr));
    console.log("------------");
    console.log(JSON.parse(artt));
    that.$nextTick(() => {
      var a = this.$refs.qrCodeDiv;
      a.innerHTML = "";
      new QRCode(this.$refs.qrCodeDiv, {
        text: JSON.stringify(jstr.createDate.qjCode),
        width: 120,
        height: 120,
        colorDark: "#333333",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
    }, 2000);
  },
  methods: {
    back() {
      console.log(this.$route.params);
      if (this.$route.params.sz == 0) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.sz == 1) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.sz == 2) {
        this.$router.push("/jdhome/jdkd");
      }
      if (this.$route.params.infos.id == 10) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.infos.id == 11) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.infos.id == 12) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    but() {
      console.log(1111);
      this.hi = true;
    },
    prints() {
      // var prints = document.getElementById('single-info');
      prints.title = "打印的标题";
    },
  },
};
</script>
<style scoped>
#printTest {
  display: flex;
}
.imgww {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.text {
  line-height: 32px;
}
.top-r {
  position: relative;
  right: -83%;
}
.imgw {
  width: 190px;
  height: 190px;
}
.c {
  position: absolute;
  top: 58%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.s {
  text-align: center;
  margin: 10px 0;
  margin-bottom: 30px;
  font-size: 18px;
}
.s1 {
  margin: 10px 0;
  /* margin-bottom: 30px; */
  /* font-size: 18px; */
}
.but {
  margin: 0 auto;
  display: block;
  width: 190px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  background-color: rgba(243, 12, 12, 100);
}
.xplist {
  font-size: 12px;
  color: black;
  margin: 10px;
}
.xplists {
  color: black;
  position: absolute;
  height: 400px;
  top: 10%;
  left: 40%;
}
.ffff {
  width: 800px;
}
</style>
<style  media="printTest">
@page {
  margin: 15mm; /* this affects the margin in the printer settings */
  width: 130mm;
  height: 75mm;
  background-color: red;
  size: 12mm;

}
@media print {
  #box {
    height: 100%;
   
  }
}
</style>