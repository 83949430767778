import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import home from '../views/home.vue'
import hotelList from '../components/hotellist.vue'
import jc from '../components/jc.vue'
import kd from '../components/kd.vue'
import zj from '../components/zj.vue'
import money from '../components/money.vue'
import note from '../components/note.vue'
import other from '../components/other.vue'
import addjd from '../components/addModel.vue'
import editjd from '../components/editModel.vue'
import s from '../components/jiudian/s.vue'
import e from '../components/jiudian/e.vue'
import czrz from '../components/caozuorizhi/caozuorizhi.vue'
import gglb from '../components/guanggao/guanggao.vue'
import addgg from '../components/guanggao/addgg.vue'
//酒店
import jdhome from '../views/jdhome.vue'
import jdjc from '../components/jiudian/jc.vue'
import jdzj from '../components/jiudian/zj.vue'
import jdkd from '../components/jiudian/kd.vue'
import jdmoney from '../components/jiudian/money.vue'
import jdewm from '../components/jiudian/jdewm.vue'
import jdxllist from '../components/jiudian/xllist.vue'
import jdperson from '../components/jiudian/person.vue'
import jdlrinfo from '../components/jiudian/lrinfo.vue'
import jdsuccess from '../components/jiudian/success.vue'
import jdaddperson from '../components/jiudian/addperson.vue'
import jdeditperson from '../components/jiudian/editperson.vue'
import jdaddxlp from '../components/jiudian/addxlp.vue'
import jdeditxlp from '../components/jiudian/editxlp.vue'
import jdpay from '../components/jiudian/pay.vue'
import jdaddinfo from '../components/jiudian/addinfo.vue'
import jdcopy from '../components/jiudian/copy.vue'
import jdxtedit from '../components/jiudian/xtedit.vue'
import jdcg from '../components/jiudian/cg.vue'
import smzfym from '../components/jiudian/smzfym.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err=>{err})
}
const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },{
    path: '/home',
    component: home,
    redirect:'/home/hotelList',
    children:[
      { 
        path: 'hotelList',
        name: 'hotelList',
        component: hotelList
      },{ 
        path: 'jc',
        name: 'jc',
        component: jc
      },{ 
        path: 'kd',
        name: 'kd',
        component: kd
      },{ 
        path: 'zj',
        name: 'zj',
        component: zj
      },{ 
        path: 'money',
        name: 'money',
        component: money
      },{ 
        path: 'note',
        name: 'note',
        component: note
      },{ 
        path: 'other',
        name: 'other',
        component: other
      },{
        path:'/addjd',
        name:'addjd',
        component:addjd
      },{
        path:'/editjd',
        name:'editjd',
        component:editjd
      },{
        path:'czrz',
        name:'czrz',
        component:czrz
      },{
        path:'guanggao',
        name:'gglb',
        component:gglb
      },{
        path:'/addgg',
        name:'addgg',
        component:addgg
      }
    ]
  },{
    path:'/jdhome',
    component: jdhome,
    redirect:'/jdhome/jdjc',
    children:[
      {
        path: 'jdjc',
        name: 'jdjc',
        component: jdjc
      },{
        path: 'jdzj',
        name: 'jdzj',
        component: jdzj
      },{
        path: 'jdkd',
        name: 'jdkd',
        component: jdkd
      },{
        path: 'jdmoney',
        name: 'jdmoney',
        component: jdmoney
      },{
        path: 'jdewm',
        name: 'jdewm',
        component: jdewm
      },{
        path: 'jdxllist',
        name: 'jdxllist',
        component: jdxllist
      },{
        path: 'jdperson',
        name: 'jdperson',
        component: jdperson
      },{
        path: 'jdlrinfo',
        name: 'jdlrinfo',
        component: jdlrinfo
      },{
        path: 'jdsuccess',
        name: 'jdsuccess',
        component: jdsuccess
      },{
        path: 'jdaddperson',
        name: 'jdaddperson',
        component: jdaddperson
      },{
        path: 'jdeditperson',
        name: 'jdeditperson',
        component: jdeditperson
      },{
        path: 'jdaddxlp',
        name: 'jdaddxlp',
        component: jdaddxlp
      },{
        path: 'jdeditxlp',
        name: 'jdeditxlp',
        component: jdeditxlp
      },{
        path: 'jdpay',
        name: 'jdpay',
        component: jdpay
      },{
        path: 'jdaddinfo',
        name: 'jdaddinfo',
        component: jdaddinfo
      },{
        path: 'jdcopy',
        name: 'jdcopy',
        component: jdcopy
      },{
        path: 'jdxtedit',
        name: 'jdxtedit',
        component: jdxtedit
      },{
        path: 'jdcg',
        name: 'jdcg',
        component: jdcg
      },{
        path:'s',
        name:'s',
        component:s
      },{
        path:'e',
        name:'e',
        component:e
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
