<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 店员列表/编辑店员 </a-col>
        <a-col :span="1" class="top-rrr"> <a-button @click="back">返回</a-button> </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 姓名 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 登陆账号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.account" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 登陆密码 </a-col>
          <a-col :span="4">
            <a-input type="password" v-model="form.pass" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 员工头像 </a-col>
          <a-col :span="4">
            <label for="male">
             <div class="yuan">
                <img :src="imageUrl"  style="width:100%;height:100%"/>
             </div>
             <input type="file" id="male"  @change="fileChange" style="display:none">
            </label>
              <!-- <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="https://api.miaocun.net/api/rule/upload"
              :headers=headers
              :before-upload="beforeUpload"
              @change="handleChange"
              :remove="remove"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload> -->
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-button type="danger" @click="qr"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: { 
        name: "",
        account:'',
        pass:'',
        id:''
       },
        headers:{
        token:sessionStorage.getItem('token')

      },
      imgs:0,
      loading: false,
      imageUrl: "",
      imageUrls:'',
      upimg:'',
      aa:''
    };
  },
  mounted(){
    
    let info =  this.$route.params.infos
    console.log(info);
    this.form.name = info.userName
    this.form.account = info.account
    this.form.pass=info.passWord
    this.imageUrl = info.headUrl
    this.form.id = info.id
    let s = this.imageUrl.split('/')
    // let ss = `${s[3]}/${s[4]}/${s[5]}`
    // let api = 'https://api.miaocun.net/tupian'+'/'+ ss
    // console.log(s,ss,api);
    // this.$axios(api,{responseType: 'arraybuffer'}).then(res=>{
    //     this.aa = new Buffer.from(res.data, 'binary').toString('base64')
    // })
   
  },
  methods: {
    back(){
      this.$router.push('/jdhome/jdperson')
    },
    fileChange(e){
      this.imgs = 1
      var file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e)=>{
        this.imageUrl = e.target.result
        this.imageUrls = e.target.result
      }
      reader.readAsDataURL(file)

    },
    qr(){
      if(this.imgs == 0){
          this.upimg = this.imageUrl
      }
      if(this.imgs==1){
         this.upimg = this.imageUrls 
      }
      let option = {
          url: "/api/" + "User" + "/" + "updateUserXcx",
          method: "post",
          data: {
          id:this.form.id,
          userName:this.form.name,
          passWord:this.form.pass,
          account:this.form.account,
          updateDate:this.$time,
          headUrl:this.upimg.split(',')[1] || this.upimg,
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
          imges:this.imgs
          },
        };
        this.$http(option).then((res) => {
          if(res.data.code==1){
            this.$router.push('/jdhome/jdperson')
            
          }else{
             this.$message.success(res.data.msg);
          }
            
        });
    },
    qx(){
      this.$router.push("/jdhome/jdperson");
    },
     beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange(info) {
      console.log(info);
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        this.imageUrl = info.file.response.data.headUrl;
        console.log(this.imageUrl)
        this.loading = false;
      }
    },
    remove(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -87%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-select-picture-card{
  
     border-radius: 50% !important;
 
}
.ant-upload{
 width: 128px !important;
  height: 128px !important;
   /* border-radius: 50% !important; */
}
.yuan{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid #d9d9d9;
}
img{
  width: 128px;
  height: 128px;
  border-radius: 50%;
}
</style>