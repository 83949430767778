<template>
  <div>
    <div style="overflow: auto;" class="top">
      <a-row class="top">
        <a-col :span="2" class="text" style="width: 13%;"> 广告管理/广告列表 </a-col>
      </a-row>
      <div class="top">
        <div class="but" @click="addgg">添加</div>
      </div>
      <div class="top">
          <a-table :loading="loading" bordered :columns="columns" :data-source="data" :rowKey="(record, index) => {return index;}">
            <template slot="id" slot-scope="record">
               {{ record.id }}
            </template>
             <template slot="action" slot-scope="record">
                 <div class="edd">
                     <div @click="edit(record)">编辑</div>
                     <div @click="del(record)">删除</div>
                 </div>
             </template>
          </a-table>
      </div>
    </div>
    <a-modal 
      v-model="visible" 
      :footer="null" 
      width='300px'
      centered
      :maskClosable=false >
        <div class="ewmstyle">
        <img :src="ewmurl" style="width:100%">
        </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
        text:'',
        visible:false,
        ewmurl:'',
        loading:false,
        columns:[
            {
                title: '序号',
                scopedSlots: { customRender: 'id' }
            },{
                title: '标题',
                dataIndex: 'title'
            },{
                title: '新建时间',
                dataIndex: 'create_time'
            },{
                title: '操作',
                width: '20%',
                scopedSlots: { customRender: 'action' }
            }
        ],
        data:[]
    };
  },
  mounted(){

     this.defaultList()
  },
  methods:{
    //默认列表
      defaultList(){
         this.loading=true
          let option ={
              url:'/api/'+'Advertise'+'/'+'index',
              method:'post',
          }
          this.$http(option).then(res=>{
               this.loading=false
              this.data = res.data.data.data
          })
      },

     async del(info){
         let del = {
             url:'/api/'+'Advertise'+'/'+'del',
             method:'post',
             data:{
               id:info.id
             }
         }
       let res = await this.$http(del)
       if(res.data.code==1){
         this.$message.warning("操作成功");
         
       }
       this.defaultList()
       
      },

      //编辑
      edit(info){
        this.$router.push({name:'addgg',params:{infos:info}})
      },
      addgg(){
        console.log(this.$router);
        this.$router.push('/addgg')
      },
      //重置
      result(){
         this.text = ''
      }
  }
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin: 0 20px;
}
.top-e {
  margin-right: 20px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd{
    display: flex;
    justify-content: space-between;
}
.edd div:hover{
    cursor: pointer;
}
.ewmstyle{
  width: 260px;
  height: 260px;
  overflow: hidden;
  margin-top: 20px;
}
</style>