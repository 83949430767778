<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 酒店列表/编辑酒店 </a-col>
        <a-col :span="1" class="top-r">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%" class="note"> 酒店名称 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%" class="note"> 酒店地址 </a-col>
          <a-col :span="4">
            <a-input v-model="form.addr" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%" class="note">
            <span style="color: red">*</span>酒店电话
          </a-col>
          <a-col :span="4">
            <a-input
              v-number-only
              @blur="animate"
              v-model="form.iphone"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%" class="note"> 登录账号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.account" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%" class="note"> 登录密码 </a-col>
          <a-col :span="4">
            <a-input type="password" v-model="form.pass" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-button type="danger" @click="edit"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="result"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      maxLength: 11,
      form: {
        id: "",
        name: "",
        addr: "",
        iphone: "",
        account: "",
        pass: "",
      },
    };
  },
  mounted() {
    this.form.id = this.$route.params.infos.id;
    this.form.name = this.$route.params.infos.name;
    this.form.addr = this.$route.params.infos.address;
    this.form.iphone = this.$route.params.infos.phone;
    this.form.account = this.$route.params.infos.account;
    this.form.pass = this.$route.params.infos.passWord;
  },
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
  methods: {
    animate() {
      // let reg = /^1[0-9]{10}$/
      // let str = this.form.iphone;
      // if(reg.test(str)){
      //     // this.$message.warning("请认真填写");
      //  }else{
      //   this.$message.warning("请输入正确手机号");
      //  }
      var sss = this.form.phone.replace(/[^\d]/g, "");
      if (sss == 1) {
        this.$message.warning("请输入数字");
      } else {
        this.form.phone = this.form.phone.replace(/[^\d]/g, "");
      }
    },
    edit() {
      let option = {
        url: "/api/" + "Hotel" + "/" + "updateHotel",
        method: "post",
        data: {
          id: this.form.id,
          number: "",
          name: this.form.name,
          account: this.form.account,
          address: this.form.addr,
          phone: this.form.iphone,
          employee: 0,
          passWord: this.form.pass,
        },
      };
      this.$http(option).then((res) => {
        console.log(res);
        if(res.data.code==1){
             this.$message.success("保存成功");
          this.$router.push("/home/hotelList");

          }else{
             this.$message.error("账号重复");
          }
        // this.$router.push("/home/hotelList");
      });
    },
    result() {
      this.$router.push("/home/hotelList");
    },
    back() {
      this.$router.push("/home/hotelList");
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -87%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>