var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"imgw",attrs:{"src":require("../assets/v2_qlu5y4.png")}})]),_c('div',{staticClass:"hotel"},[_vm._v("酒店行李寄存系统")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'userName',
        { rules: [{ required: true, message: '请输入账号' }] },
      ]),expression:"[\n        'userName',\n        { rules: [{ required: true, message: '请输入账号' }] },\n      ]"}],staticClass:"input",attrs:{"placeholder":"请输入账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: '请输入密码' }] },
      ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: '请输入密码' }] },\n      ]"}],staticClass:"input",attrs:{"type":"password","placeholder":"请输入密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }