import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
Vue.prototype.$axios = axios
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { download } from '@/http/'
Vue.use(Antd);
Vue.prototype.download = download

//调用打印机
import Print from 'vue-print-nb'
Vue.use(Print);
//全局方法
import {
  httpget
} from './http'
Vue.prototype.$http = httpget

//全局时间
var myDate = new Date();
var y = myDate.getFullYear();
var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
var r = myDate.getDate() < 10 ? '0' + (myDate.getDate()) : myDate.getDate();
var h = myDate.getHours() < 10 ? '0' + (myDate.getHours()) : myDate.getHours();
var f = myDate.getMinutes() < 10 ? '0' + (myDate.getMinutes()) : myDate.getMinutes();
let time = `${y}-${m}-${r} ${h}:${f}`
Vue.prototype.$time = time

//全局富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

Vue.filter('aa',function(val){
   if(val == 0){
     return '微信'
   }
   if(val==1){
     return '支付宝'
   }
})
//全局过滤器
Vue.filter('stateval', function (val) {
  if (val == 0) {
    return '待寄存'
  }
  if (val == 1) {
    return '寄存中'
  }
  if (val == 2) {
    return '已完成'
  }
  if (val == 3) {
    return '已取消'
  }
})
Vue.filter('typeval', function (val) {
  if (val == 0) {
    return '扫码裹件'
  }
  if (val == 1) {
    return '纸质条码裹件'
  }
})



Vue.filter('timeout', function (val) {
  // console.log(val)
  let rule = JSON.parse(sessionStorage.getItem('rule'))
  let day = rule.day*24
  let deposit = rule.deposit
  var cur = val.createDate
  var customTime = cur.replace("-", "/");
  // console.log(cur,customTime)
  var bzsj = new Date(Date.parse(customTime));
  var now = new Date()
  var startDate = bzsj.getTime()
  var curtime = now.getTime()
  // console.log(curtime,startDate)
  var msecNum = curtime - startDate
  var h = parseInt(msecNum /1000/(60 * 60))
  // console.log(day,h)
  if(val.timeout==1){
    return '已超时'

  }else{
   
    if(val.state == 0){  //待寄存
      if(deposit - h <= 0 ){
        let option = {
          url: "/api/" + "Luggage" + "/" + "upLuggage",
          method: 'post',
          data: {
            id: val.id
          }
        }
        var v = new Vue()
        v.$http(option).then(res => {
           
        })
        return '已超时'
      }else{
        return '未超时'
      }
  }
  if(val.state == 1 || val.state == 2 || val.state == 3){ //寄存中
    // console.log( val.state)
    if(day - h <= 0){
      let option = {
        url: "/api/" + "Luggage" + "/" + "updLuggage",
        method: 'post',
        data: {
          id: val.id
        }
      }
      var v = new Vue()
      v.$http(option).then(res => {
        
      })
      return '已超时'
    }else{
      return '未超时'
    }
  }

  }

})
Vue.filter('timeout1', function (val) {
  let rule = JSON.parse(sessionStorage.getItem('rule'))
  let day = rule.day*24
  let deposit = rule.deposit
  var cur = val.createDate
  var customTime = cur.replace("-", "/");
  var bzsj = new Date(Date.parse(customTime));
  var now = new Date()
  var startDate = bzsj.getTime()
  var curtime = now.getTime()
  var msecNum = curtime - startDate
  var h = parseInt(msecNum / 3600000)
  if(val.timeout==1){
    return '已超时'

  }else{
    if(val.state == 0){  //待寄存
      if(deposit - h <=0){
        let option = {
          url: "/api/" + "Expressage" + "/" + "upExpressage",
          method: 'post',
          data: {
            id: val.id
          }
        }
        var v = new Vue()
        v.$http(option).then(res => {
           
        })
        return '已超时'
      }else{
        return '未超时'
      }
  }
  if(val.state == 1  || val.state == 2 || val.state == 3){ //寄存中
    if(day - h <=0){
      let option = {
        url: "/api/" + "Expressage" + "/" + "updExpressage",
        method: 'post',
        data: {
          id: val.id
        }
      }
      var v = new Vue()
      v.$http(option).then(res => {
       
      })
      return '已超时'
    }else{
      return '未超时'
    }
  }

  }
  
})
Vue.filter('wzval', function (val) {
  if (val == 0) {
    return '微信'
  }
  if (val == 1) {
    return '支付宝'
  }
})
Vue.filter('wayval', function (val) {
  // console.log('val',val)
  if(val.length>0){
    if(val[0].state==2){
      if(val!=""){
        if (val[0].way == 0) {
          return '自取'
        }
        if (val[0].way == 1) {
          return '他人代取'
        }
        if (val[0].way == 2) {
          return '身份证信息取件'
        }
        if (val[0].way == 3) {
          return '后台扫码取件'
        }
        if (val[0].way == 4) {
          return '酒店操作直接取出'
        }
      }
  
    }
  }
 
})

Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')