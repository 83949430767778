<template>
    <div>
        <div class="aglin">
            <img src="../../assets/err.png" style="width:100%;height:100%">
             <p class="zfcg">支付失败</p>
        </div>
       
    </div>
</template>
<script>
    export default{
        data(){
            return{

            }
        }
    }
</script>
<style scoped>
.aglin{
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
}
.zfcg{
    text-align: center;
    margin-top: 10px;
}
</style>
