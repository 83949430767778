<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 订单列表/订单信息编辑 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top" style="color: red">提示：请先扫码行李牌二维码</div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 行李牌 </a-col>
          <a-col :span="4">
            <a-input :disabled="form.xlp" v-model="form.xlp" @blur="sqjd" @change="sqjd" />
          </a-col>
          <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="plus-circle" @click="addmark" />
          </a-col>
        </a-row>
      </div>
      <div class="top" v-for="(item, index) in addinput" :key="index">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%">
            {{ item.xlp }}{{ index + 1 }}
          </a-col>
          <a-col :span="4">
            <a-input
              :disabled="item.dis"
              v-model="item.xlpnum"
              placeholder="请输入"
              @change="sqjd1(item)"
            />
          </a-col>
          <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="delete" @click="delinput(index)" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 姓名 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"
            ><span style="color: red">*</span> 手机号
          </a-col>
          <a-col :span="4">
            <a-input
              v-model="form.phone"
              @blur="animate"
              ref="inpf"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 房间号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.room" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button
          :disabled="disabs"
          type="danger"
          @click="success"
          :class="{ gary: garyor }"
        >
          确认
        </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
var timer = null;
var timers = null;
export default {
  data() {
    return {
      // maxLength:11,
      addinput: [],
      garyor: true,
      disabs: false,
      form: {
        xlp: "",
        name: "",
        phone: "",
        room: "",
      },
      aa: "",
      yzmcode: "",
      newarr: [],
    };
  },
  mounted() {
    // this.$refs.inpf.focus()

    this.getinfo = this.$route.params.infos;
    // console.log(this.getinfo);
    this.form.name = this.getinfo.userName;
    this.form.phone = this.getinfo.phone;
    this.form.room = this.getinfo.homeNum;
    let arr = [];
    console.log(this.getinfo.qj);
    for (let i in this.getinfo.qj) {
      arr.push(this.getinfo.qj[i].mark);
      console.log(arr);
    }

    this.form.xlp = arr[0];
    let arrs = arr.slice(1, arr.length);
    console.log(arrs);
    arrs.map((item) => {
      let j = {
        xlp: "行李牌",
        xlpnum: item,
        dis:true,
      };
      this.addinput.push(j);
    });

    if (this.form.xlp) {
      this.garyor = !this.garyor;
    }
  },
  methods: {
    animate() {
      // let reg = /^1[0-9]{10}$/
      // let str = this.form.phone;
      // if(reg.test(str)){
      //     // this.$message.warning("请认真填写");
      //  }else{
      //   this.$message.warning("请输入正确手机号");
      //  }
      var sss = this.form.phone.replace(/[^\d]/g, "");
      if (sss == 1) {
        this.$message.warning("请输入数字");
      } else {
        this.form.phone = this.form.phone.replace(/[^\d]/g, "");
      }
    },
    sqjd() {
      console.log(this.form.xlp);
      this.disabs = true;
      this.input1(this.form.xlp);
    },

    input1(xlp) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (xlp) {
          let option = {
            url: "/api/" + "Mark" + "/" + "getMarkList",
            method: "post",
            data: {
              mark: xlp,
              hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
            },
          };
          this.$http(option).then((res) => {
            if (res.data.data.lists.length == 0) {
              this.$message.warning("号码牌不存在");
              this.disabs = true;
            } else {
              this.disabs = false;
              let option = {
                url: "/api/" + "Luggage" + "/" + "getMark",
                method: "post",
                data: {
                  mark: this.form.xlp,
                  hotelName: JSON.parse(sessionStorage.getItem("info")).name,
                },
              };
              this.$http(option).then((res) => {
                if (res.data.data.stat == 0) {
                  this.newarr.push(this.form.xlp);
                  this.disabs = false;
                } else {
                  this.$message.warning("重复号码牌请重新填写");
                  this.disabs = true;
                }
              });
            }
          });
        }
      }, 500);
    },

    input2(info) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (info.xlpnum) {
          this.$nextTick(() => {
            let option = {
              url: "/api/" + "Mark" + "/" + "getMarkList",
              method: "post",
              data: {
                mark: info.xlpnum,
                hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
              },
            };
            this.$http(option).then((res) => {
              if (res.data.data.lists.length == 0) {
                this.$message.warning("号码牌不存在");
                this.disabs = true;
              } else {
                this.disabs = false;
                let option = {
                  url: "/api/" + "Luggage" + "/" + "getMark",
                  method: "post",
                  data: {
                    mark: info.xlpnum,
                    hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
                  },
                };
                this.$http(option).then((res) => {
                  if (res.data.data.stat == 0) {
                    this.newarr.push(info.xlpnum);
                    this.disabs = false;
                  } else {
                    
                    this.$message.warning("重复号码牌请重新填写");
                    this.disabs = true;
                  }
                });
              }
            });
          });
        }
      }, 500);
    },

    sqjd1(info) {
      this.disabs = true;
      this.input2(info);
    },
    yzm() {
      //随机生成验证码
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
      for (let i = 0; i < 6; i++) {
        let code = random[Math.floor(Math.random() * 10)];
        this.yzmcode += code;
      }
    },
    delinput(index) {
      this.addinput.splice(index, 1);
    },
    bh(e) {
      if (this.form.xlp) {
        this.garyor = false;
      } else {
        this.garyor = true;
      }
    },
    addmark() {
      this.addinput.push({
        xlp: "行李牌",
        xlpnum: "",
      });
    },
    success() {
      if (this.form.xlp && this.form.name && this.form.phone) {
        if (this.$route.params.id == 1) {
          this.defa(0);
        }
        if (this.$route.params.id == 2) {
          this.defa(1);
        }
        if (this.$route.params.id == 3) {
          let s = [this.form.xlp];
          this.addinput.map((item) => {
            s.push(item.xlpnum);
          });
          var str = s.join(",");
          let option = {
            url: "/api/" + "Expressage" + "/" + "upExpressageWeb",
            method: "post",
            data: {
              id: this.getinfo.id,
              userName: this.form.name,
              phone: this.form.phone,
              homeNum: this.form.room,
              mark: str,
            },
          };
          this.$http(option).then((res) => {
            // this.$router.push("/jdhome/jdkd");
            console.log(res.data.code);
            if (res.data.code == 1) {
              this.$router.push({
                name: "jdsuccess",
                params: {
                  sz: 2,
                  names: "存件成功",
                  infos: res.data.data,
                },
              });
            }
          });
        }
      } else {
        this.$message.warning("不能为空");
      }
    },
    defa(num) {
      //  this.sqjd()
      // this.yzm();
      let s = [this.form.xlp];
      this.addinput.map((item) => {
        s.push(item.xlpnum);
      });
      var str = s.join(",");
      let option = {
        url: "/api/" + "Luggage" + "/" + "upLuggageWeb",
        method: "post",
        data: {
          id: this.getinfo.id,
          userName: this.form.name,
          phone: this.form.phone,
          homeNum: this.form.room,
          mark: str,
        },
      };
      this.$http(option).then((res) => {
        console.log(res.data.code);
        if (res.data.code == 1) {
          this.$router.push({
            name: "jdsuccess",
            params: {
              sz: num,
              names: "存件成功",
              infos: res.data.data,
            },
          });
        }
        //   if (this.$route.params.id == 1) {
        //         this.$router.push("/jdhome/jdjc");
        //   }
        //  if (this.$route.params.id == 2) {
        //        this.$router.push("/jdhome/jdzj");
        //   }
      });
    },
    back() {
      if (this.$route.params.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    qx() {
      if (this.$route.params.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.gary {
  background: gray !important;
  border: gray;
}
</style>