<template>
  <div>
    <div class="top" style=" overflow: auto">
      <a-row class="top"><a-col :span="4" class="text" style="width: 14%">
        店员管理/店员列表
        </a-col></a-row>
      <a-row class="top">
        <a-col :span="3" class="top-l">
          <a-input-search
            placeholder="请输入店员姓名"
            style="width: 200px"
            v-model="text"
            @search="onSearch"
          />
        </a-col>
        <a-col :span="1" class="top-l" style="margin-left: 10%">
          <a-button @click="xc">查询</a-button>
        </a-col>
        <a-col :span="1" class="top-rr">
          <a-button @click="result">重置</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <div class="but" @click="addperson">添加店员</div>
      </div>
      <div class="top">
        <a-table
          bordered
          :loading="loading"
          :columns="columns"
          :data-source="data"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="action" slot-scope="record">
            <div class="edd">
              <div @click="edit(record)">编辑</div>
              <div @click="del(record)">删除</div>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "",
      loading: false,
      columns: [
        {
          title: "序号",
          dataIndex: "id",
        },
        {
          title: "店员姓名",
          dataIndex: "userName",
        },
        {
          title: "注册时间",
          dataIndex: "creatDate",
        },
        {
          title: "店员账号",
          dataIndex: "account",
        },
        {
          title: "操作",
          width: "15%",
          scopedSlots: { customRender: "action" },
        },
      ],
      data: [],
    };
  },
  mounted() {
    this.defa();
  },
  methods: {
    defa() {
      this.loading = true;
      let option = {
        url: "/api/" + "User" + "/" + "getUserXcxss",
        method: "post",
        data: {
          hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
          userName:"",
        },
      };
      this.$http(option).then((res) => {
        this.loading = false;
        this.data = res.data.data.lists;
      });
    },
    onSearch() {},
    edit(info) {
      this.$router.push({ name: "jdeditperson", params: { infos: info } });
    },
    addperson() {
      this.$router.push("/jdhome/jdaddperson");
    },
    del(info) {
      let option = {
        
        url: "/api/" + "User" + "/" + "delUserXcx",
        method: "post",
        data: {
          id: info.id,
        },
      };
      this.$http(option).then((res) => {
        this.defa();
      });
    },
    xc() {
      //  console.log( JSON.parse(sessionStorage.getItem('info')).hotelId);return
       
      if (this.text) {
       
        let option = {
          // url: "/api/" + "User" + "/" + "getUserXcx",
           url: "/api/" + "User" + "/" + "getUserXcxss",
          method: "post",
          data: {
            userName: this.text,
            hotelId: JSON.parse(sessionStorage.getItem('info')).hotelId
          },
        };
        this.$http(option).then((res) => {
          this.data = res.data.data.lists;
        });
      } else {
        this.defa();
      }
    },
    result() {
      this.text = "";
    },
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
</style>