<template>
  <div class="menu">
    <ul class="meun-list">
      <li class="meun-li" v-for="(item, index) in list" :key="index">
        <ul>
          <li @click="listTog(item)" >
            <a-icon type="unordered-list" class="icon" />{{ item.title }}
          </li>
        </ul>
        <ul v-if="item.toteltog">
          <li
            v-for="(item, index) in item.subtitle"
            :key="index"
            :class="{ bj:itemnum == item.xh,meunLiLi:meunLiLiys}"
            @click="subIndex(item,index)"
          >
            {{ item.sub }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      meunLiLiys:true,
      itemnum:-1,
      list: [
        { title: "酒店管理", toteltog: false, subtitle: [{ sub: "酒店列表",xh:4 }] },
        {
          title: "订单管理",
          toteltog: false,
          subtitle: [
            { sub: "行李寄存订单列表" ,xh:0},
            { sub: "行李转交订单列表" ,xh:1},
            { sub: "快递寄存订单列表" ,xh:2},
          ],
        },
        {
          title: "短信管理",
          toteltog: false,
          subtitle: [{ sub: "充值列表",xh:5 }, { sub: "短信设置" ,xh:6}],
        },
        {
          title: "其他内容设置",
          toteltog: false,
          subtitle: [{ sub: "其他内容设置" ,xh:7}],
        },
         {
          title: "酒店操作日志",
          toteltog: false,
          subtitle: [{ sub: "酒店操作日志" ,xh:8}],
        },
        {
          title: "广告管理",
          toteltog: false,
          subtitle: [{ sub: "广告列表" ,xh:9}],
        },
      ],
    };
  },
  methods: {
    listTog(info) {
      info.toteltog = !info.toteltog;
    },
    subIndex(info,index){
         this.itemnum = info.xh
         if(info.sub == '酒店列表'){
             this.$router.push('/home/hotelList')
         }
         if(info.sub == '行李寄存订单列表'){
             this.$router.push('/home/jc')
         }
         if(info.sub == '行李转交订单列表'){
             this.$router.push('/home/zj')
         }
         if(info.sub == '快递寄存订单列表'){
            this.$router.push('/home/kd')
         }
         if(info.sub == '充值列表'){
            this.$router.push('/home/money')
         }
         if(info.sub == '短信设置'){
            this.$router.push('/home/note')
         }
         if(info.sub == '其他内容设置'){
           this.$router.push('/home/other')
         }
         if(info.sub == '酒店操作日志'){
            this.$router.push('/home/czrz')
         }
          if(info.sub == '广告列表'){
            this.$router.push('/home/guanggao')
         }
    }
  }
};
</script>
<style scoped>
.menu {
  padding-top: 20px;
}
.meun-list {
  color: white;
}
.meun-li {
  line-height: 50px;
}
.meun-li:hover{
    cursor: pointer;
}
.meun-list .icon {
  padding: 0 10px;
  padding-left: 25px;
}
.meunLiLi {
  height: 50px;
  line-height: 50px;
  padding-left: 50px;
}
.meun-li-li:hover {
  background: #e6b666;
  cursor: pointer;
}
.bj{
  background: #e6b666;
}
</style>