<template>
  <div>
    <div class="header">
      <div class="header-top">
        <div>酒店行李寄存系统</div>
        <div class="icon" @click="logout">{{perinfo.name}}
          <a-icon type="poweroff" style="margin-left:10px" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="left">
        <silder></silder>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import silder from '../components/silder'
  export default {
    name: "home",
    components: {
      silder
    },
    data() {
      return {
        perinfo: {}
      };
    },
    mounted() {
      this.perinfo = JSON.parse(sessionStorage.getItem('info'))
    },
    methods: {
      logout() {
        window.location.href = "/";
        sessionStorage.clear()
      }
    },
  };
</script>
<style scoped>
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background: #e6b666;
  }

  .header-top {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .header-top div:first-child {
    font-size: 24px;
    color: white;
    font-weight: 550;
  }

  .header-top div:last-child {
    color: white;
    font-weight: 550;
  }

  .main {
    display: flex;
  }

  .left {
    width: 15%;
    height: 90.5vh;
    background: #7a7a7a;
  }

  .content {
    width: 85%;
    height: 90.5vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .icon:hover {
    cursor: pointer;
  }

  .meun {
    background: #7a7a7a;
    color: white;
  }
</style>