<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {
    let option = {
      url: "/api/" + "Rule" + "/" + "getRule",
      method: "post",
    };
    this.$http(option).then((res) => {
      let rules = JSON.stringify(res.data.data.lists[0])
      sessionStorage.setItem('rule',rules)
    });
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.top::-webkit-scrollbar {
    display: none;
}
.top-r {
  position: relative;
  right: -77% !important;
}
.top-e{
  margin-right: 30px !important;
  margin-left: 57px !important;
}
.top-r{
  margin-left: 10px !important;
}
.top-dx{
  margin-left: 37%;
}
.top-back{
  margin-left: 81%;
}
.top-backk{
  margin-left: 85%;
}
.top-ll{
  margin-left: 10% !important;
}
.top-rr{
  margin-left: 4%;
}
.top-rrr{
  margin-left: 77%;
}
.xs:hover{
  cursor: pointer;
}
</style>
