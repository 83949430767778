<template>
  <div>
    <div style="height: 70vh; overflow: auto" class="top">
      <a-row class="top">
        <a-col :span="4" class="text" style="width: 13%">
          短信管理/充值列表
        </a-col></a-row
      >
      <a-row class="top">
        <a-col :span="3" class="top-l">
          <a-input placeholder="酒店名称" v-model="lists.name" />
        </a-col>
        <a-col :span="3" class="top-l">
          <a-date-picker
            :value="lists.time"
            @change="onChange"
            style="width: 100%"
            placeholder="选择日期"
          />
        </a-col>
        <a-col :span="1" class="top-l">
          <a-button @click="about">查询</a-button>
        </a-col>
        <a-col :span="1" class="top-r">
          <a-button @click="result">重置</a-button>
        </a-col>
        <a-col :span="1" class="top-dx">
          <a-button @click="setup">短信费用设置</a-button>
        </a-col>
      </a-row>
      <div class="top top-flex">
        <div>累计充值金额：{{ money.pileup / 100 }}元</div>
        <div>微信充值金额：{{ money.wx / 100 }}元</div>
        <div>支付宝充值金额：{{ money.zfb / 100 }}元</div>
      </div>
      <div class="top">
        <a-table
          :loading="loading"
          bordered
          :columns="columns"
          :data-source="data"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="type" slot-scope="record">
            {{ record.type | wzval }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import editpersonVue from "./jiudian/editperson.vue";
export default {
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "id",
        },
        {
          title: "充值时间",
          dataIndex: "createDate",
        },
        {
          title: "充值方式",
          // dataIndex: 'type'
          scopedSlots: { customRender: "type" },
        },
        {
          title: "充值酒店",
          dataIndex: "hotelName",
        },
        {
          title: "充值条数",
          dataIndex: "number",
        },
        {
          title: "充值金额(元)",
          dataIndex: "money",
        },
      ],
      data: [],
      money: {
        pileup: 0,
        wx: 0,
        zfb: 0,
      },
      lists: {
        name: "",
        time: "",
      },
      loading: false,
    };
  },
  mounted() {
    this.defaultList();
    this.amount();
  },
  methods: {
    defaultList() {
      this.loading = true;
      let option = {
        url: "/api/" + "Note" + "/" + "getNoteList",
        method: "post",
      };
      this.$http(option).then((res) => {
        this.loading = false;
        // this.data = res.data.data.lists
        let date = res.data.data.lists;
        date.map((item) => {
          item.money = item.money / 100;
        });
        this.data = date;
      });
    },
    amount() {
      let option = {
        url: "/api/" + "Note" + "/" + "getNoteMoney",
        method: "post",
      };
      this.$http(option).then((res) => {
        this.money.pileup = res.data.data.wx + res.data.data.zfb;
        this.money.wx = res.data.data.wx;
        this.money.zfb = res.data.data.zfb;
      });
    },
    about() {
      let option = {
        url: "/api/" + "Note" + "/" + "queryNote",
        method: "post",
        data: {
          hotelName: this.lists.name,
          createDate: this.lists.time,
        },
      };
      this.$http(option).then((res) => {
        let data = res.data.data.lists;
        data.map((item) => {
          item.money = item.money / 100;
        });
        this.data = data;
      });
    },
    edit(info) {
      console.log(info);
    },
    setup() {
      this.$router.push("/home/note");
    },
    onChange(date, dateString) {
      this.lists.time = dateString;
    },
    result() {
      this.lists.name = "";
      this.lists.time = "";
    },
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -46%;
}
.top-flex {
  width: 62%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>