<template>
  <div>
    <div class="top" style="height:70vh;overflow: auto;">
      <a-row class="top"><a-col :span="4" class="text" style="width: 14%">
       行李牌管理/行李牌列表
        </a-col></a-row>
      <a-row class="top">
        <a-col :span="3" class="top-l">
          <a-input
            placeholder="请输入牌号"
            style="width: 200px"
            v-model="text"
          />
        </a-col>
        <a-col :span="1" class="top-l" style="margin-left:10%"> <a-button @click="onSearch">查询</a-button> </a-col>
        <a-col :span="1" class="top-rr"> <a-button @click="result">重置</a-button> </a-col>
      </a-row>
      <div class="top">
        <div class="but" @click="addxlp">添加行李牌</div>
      </div>
      <div class="top">
          <a-table bordered :loading="loading" :columns="columns" :data-source="data" :rowKey="(record, index) => {return index;}">
             <template slot="action" slot-scope="record">
                 <div class="edd">
                     <!-- <div @click="edit(record)">编辑</div> -->
                     <div @click="showewm(record)">行李牌二维码</div>
                     <!-- <div @click="del(record)">删除</div> -->
                 </div>
             </template>
          </a-table>
      </div>
    </div>

    <a-modal style="height: 300px;" v-model="visible" :footer="null" :maskClosable=false width='350px' centered class="model-div">
      <div class="imgw">
        <img v-bind:src="qrcode_img" alt="" width="200px">
        <a-button type="danger" style="margin:30px auto;display: block;" @click="saveImg()">
              下载
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
        text:'',
        iurl:'',
        loading:false,
        visible: false,
        columns:[
            {
                title: '序号',
                dataIndex: 'id',
            },{
                title: '牌号',
                dataIndex: 'mark'
            },{
                title: '添加时间',
                dataIndex: 'creatDate'
            },{
                title: '操作',
                width: '20%',
                scopedSlots: { customRender: 'action' }
            }
        ],
        data:[],
        qrcode_img:''
    };
  },
  mounted(){
      this.defa()
  },
  methods:{
      defa(){
        this.loading=true
         let option = {
            url: "/api/" + "Mark" + "/" + "getMarkLists",
            method: "post",
            data: {
              hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
            },
          };
          this.$http(option).then((res) => {
             this.loading=false
              this.data = res.data.data.lists
          });
      },
      onSearch(){
        if(this.text){
           let option = {
            url: "/api/" + "Mark" + "/" + "getMarkList",
            method: "post",
            data: {
              mark:this.text,
             hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
            },
          };
          this.$http(option).then((res) => {
              this.data = res.data.data.lists
          });
        }else{
          this.defa()
        }  
      },
      edit(info){
          this.$router.push({name:'jdeditxlp',params:{infos:info}})
      },
      down(){
        let dom = this.$refs.qrCodeDiv;
        
        let img = dom.children[0].toDataURL("image/png")
        console.log(dom)
        var a = document.createElement('a');      
        a.href = img
        a.download = '二维码'
        a.click();
      },
      saveImg() {
        var base64 = this.qrcode_img.toString(); // imgSrc 就是base64哈
        var byteCharacters = atob(
          base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
        );
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: undefined,
        });
        var aLink = document.createElement("a");
        aLink.download = "行李牌.jpg"; //这里写保存时的图片名称
        aLink.href = URL.createObjectURL(blob);
        aLink.click();
      },
      del(info){
        let option = {
            url: "/api/" + "Mark" + "/" + "delMark",
            method: "post",
            data: {
              mark:info.mark,
              id: info.id,
            },
          };
          this.$http(option).then((res) => {
            console.log(res);
            if(res.data.code == 0){
              this.$message.warning(res.data.msg);
            }else{
              this.$message.success('删除成功');
            }
              this.defa()
          });
      },
      addxlp(){
          this.$router.push('/jdhome/jdaddxlp')
      },
      showewm(info){
        var jdid = JSON.parse(sessionStorage.getItem('info')) 
        this.qrcode_img = info.qrCode
        this.visible = true
      },
      result(){
        this.text =''
      }
  }
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
   margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd{
    display: flex;
    justify-content: space-between;
}
.edd div:hover{
    cursor: pointer;
}
.imgw{
  width: 260px;
  height: 260px;
  padding: 10px;
  background: #fff;

}
.model-div{
  background: #fff;
}
</style>