<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 订单列表/扫货码取件 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 号码牌 </a-col>
          <a-col :span="4">
            <a-input
              v-model="form.xlp"
              placeholder="请输入"
              ref="inputVal"
              @blur="cs"
            />
          </a-col>
          <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="plus-circle" @click="addmark" />
          </a-col>
        </a-row>
      </div>
      <div class="top" v-for="(item, index) in addinput" :key="index">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%">
            {{ item.xlp }}
          </a-col>
          <a-col :span="4">
            <a-input
              v-model="item.xlpnum"
              placeholder="请输入"
              @blur="zcs(item)"
            />
          </a-col>
          <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="delete" @click="delinput(index)" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-button type="danger" @click="tj"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addinput: [],
      info: {},
      form: { xlp: "" },
      first:'',
      second:[],
      nums:''
    };
  },
  mounted() {
    this.info = this.$route.params;
    this.$refs.inputVal.focus();
    if(this.info.id == 1 || this.info.id == 2){
      this.nums = 0
    }
    if(this.info.id == 3){
      this.nums = 1
    }
  },
  methods: {
    xl(c, i, info) {
      let option = {
        url: "/api/" + "Luggage" + "/" + "queryLuggageById",
        method: "post",
        data: {
          id: c,
        },
      };
      this.$http(option).then((res) => {
        if (i == 0) {
          if(res.data.data.lists.length == 0){
              this.form.xlp = "";
              this.$message.warning("不是同一家酒店");
          }
          let ddid = res.data.data.lists[0].hotelId; //订单id
          let jdid = JSON.parse(sessionStorage.getItem("info")).id;
          if (ddid == jdid){
            // this.form.xlp = res.data.data.lists[0].mark;
            this.first = res.data.data.lists[0].mark; //传递的参数
          } else {
            this.form.xlp = "";
            this.$message.warning("不是同一家酒店");
          }
        }
        if (i == 1) {
          if(res.data.data.lists.length == 0){
              info.xlpnum = "";
              this.$message.warning("不是同一家酒店");
          }
          let ddid = res.data.data.lists[0].hotelId; //订单id
          let jdid = JSON.parse(sessionStorage.getItem("info")).id;
          
          if (ddid == jdid) {
            // info.xlpnum = res.data.data.lists[0].mark;
            this.second.push(res.data.data.lists[0].mark) //传递的参数
          } else {
            info.xlpnum = "";
            this.$message.warning("不是同一家酒店");
          }
        }
      });
    },
    kd(c, i, info) {
      let option = {
        url: "/api/" + "Expressage" + "/" + "queryExpressageById",
        method: "post",
        data: {
          id: c,
        },
      };
      this.$http(option).then((res) => {
        if(res.data.data.lists.length == 0){
              this.form.xlp = "";
              this.$message.warning("不是同一家酒店");
          }
        if (i == 0) {
          let ddid = res.data.data.lists[0].hotelId; //订单id
          let jdid = JSON.parse(sessionStorage.getItem("info")).id;
          if (ddid == jdid) {
              //  this.form.xlp = res.data.data.lists[0].mark;
              this.first = res.data.data.lists[0].mark; //传递的参数
          } else {
            this.form.xlp = "";
            this.$message.warning("不是同一家酒店");
          }
        }
        if (i == 1) {
          if(res.data.data.lists.length == 0){
              this.$message.warning("不是同一家酒店");
          }
          let ddid = res.data.data.lists[0].hotelId; //订单id
          let jdid = JSON.parse(sessionStorage.getItem("info")).id;
          if (ddid == jdid) {
            // info.xlpnum = res.data.data.lists[0].mark;
             this.second.push(res.data.data.lists[0].mark) //传递的参数
          } else {
            info.xlpnum = "";
            this.$message.warning("不是同一家酒店");
          }
        }
      });
    },
    cs() {
      // let a = JSON.stringify("460" + ":" + "0"); 试列
      let a = JSON.stringify(this.form.xlp + ":" + this.nums);
      let b = JSON.parse(a).split(":");
      let c = b[0]; //订单号
      let d = b[1]; //是行李还是快递
      if (d == 0) {
        this.xl(c, 0);
      }
      if (d == 1) {
        this.kd(c, 0);
      }
    },
    zcs(info) {
      this.$nextTick(() => {
        // let a = JSON.stringify("460" + ":" + "0"); 试列
        let a = JSON.stringify(info.xlpnum + ":" + this.nums);
        let b = JSON.parse(a).split(":");
        let c = b[0]; //订单号
        let d = b[1]; //是行李还是快递
        if (d == 0) {
          this.xl(c, 1, info);
        }
        if (d == 1) {
          this.kd(c, 1, info);
        }
      });
    },
    addmark() {
      this.addinput.push({
        xlp: "号码牌",
        xlpnum: "",
      });
    },
    delinput(index) {
       this.addinput.splice(index, 1);
       this.second.splice(index,1)
    },
    tj() {
      // let ary = [this.form.xlp];
      // this.addinput.map((item) => {
      //   ary.push(item.xlpnum);
      // });
      // 获取到扫到的取件码str 将str发送给后台
      // let str = ary.join(",");
      let ary = [this.first,...this.second]
      let str = ary.join(",");
      if (this.info.id == 1 || this.info.id == 2) {
        if (str) {
          let option = {
            url: "/api/" + "Luggage" + "/" + "updateLuggages",
            method: "post",
            data: {
              id: this.info.infos.id,
              consignee: this.info.infos.consignee,
              qjPhone: this.info.infos.phone,
              way:  this.info.infos.way,
              qjName: this.info.infos.qjName,
              identity: this.info.infos.identity,
              updateDate: this.$time,
            },
          };
          this.$http(option).then((res) => {
            console.log(res)
            if (res.data.msg == "success") {
              if (this.info.id == 1) {
                this.$router.push("/jdhome/jdjc");
              }
              if (this.info.id == 2) {
                this.$router.push("/jdhome/jdzj");
              }
            }
          });
        }
      }
      if (this.info.id == 3) {
        if (str) {
          let option = {
            url: "/api/" + "Expressage" + "/" + "updateExpressages",
            method: "post",
            data: {
              id: this.info.infos.id,
              consignee: this.info.infos.consignee,
              qjPhone: this.info.infos.phone,
              way:  this.info.infos.way,
              qjName: this.info.infos.qjName,
              identity: this.info.infos.identity,
              updateDate: this.$time,
            },
          };
          this.$http(option).then((res) => {
            if (res.data.msg == "success") {
              if (this.info.id == 3) {
                this.$router.push("/jdhome/jdkd");
              }
            }
          });
        }
      }
    },
    //返回
    back() {
      if (this.info.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.info.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.info.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    //取消
    qx() {
      if (this.info.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.info.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.info.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.note {
  line-height: 32px;
}
</style>