<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 行李牌列表/编辑行李牌 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note"> 牌号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button type="danger" @click="but"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
      },
    };
  },
  mounted() {
    this.form.id = this.$route.params.infos.id;
    this.form.name = this.$route.params.infos.mark;
  },
  methods: {
    but() {
      let option = {
        url: "/api/" + "Mark" + "/" + "updateMark",
        method: "post",
        data: {
          mark: this.form.name,
          id: this.form.id,
        },
      };
      this.$http(option).then((res) => {
        this.$router.push("/jdhome/jdxllist");
      });
    },
    back() {
      this.$router.push("/jdhome/jdxllist");
    },
    qx() {
      this.$router.push("/jdhome/jdxllist");
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>