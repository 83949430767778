<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 订单列表/客户信息录入 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top" style="color: red">提示：请先扫码行李牌二维码</div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%">
            <span style="color: red">*</span>行李牌
          </a-col>
          <a-col :span="4">
            <a-input
              v-model="form.xlp"
              placeholder="请输入"
              @change="bh"
              @blur="sqjd"
            />
          </a-col>
          <!-- <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="plus-circle" @click="addmark" />
          </a-col> -->
        </a-row>
      </div>
      <div class="top" v-for="(item, index) in addinput" :key="index">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"
            ><span style="color: red">*</span> {{ item.xlp }}{{ index + 1 }}
          </a-col>
          <a-col :span="4">
            <a-input
              v-model="item.xlpnum"
              placeholder="请输入"
              @blur="sqjd1(item)"
            />
          </a-col>
          <a-col :span="1" class="note" style="margin-left: 10px">
            <a-icon type="delete" @click="delinput(index)" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"
            ><span style="color: red">*</span> 姓名
          </a-col>
          <a-col :span="4">
            <a-input
              v-model="form.name"
              :disabled="disabled"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"
            ><span style="color: red">*</span> 手机号
          </a-col>
          <a-col :span="4">
            <a-input
              v-number-only
              @blur="animate"
              v-model="form.phone"
              :disabled="disabled"
              ref="inpf"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 房间号 </a-col>
          <a-col :span="4">
            <a-input
              v-model="form.room"
              :disabled="disabled"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button
          :disabled="disableds"
          type="danger"
          @click="success"
          :class="{ gary: garyor }"
        >
          确认
        </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //  maxLength:11,
      disabled: true,
      addinput: [],
      garyor: true,
      disableds: false,
      form: {
        xlp: "",
        name: "",
        phone: "",
        room: "",
      },
      aa: "",
      yzmcode: "",
      newarr: [],
      ch1: "",
      ch2: "",
    };
  },
  mounted() {
    // this.$refs.inpf.focus()
    console.log(JSON.parse(sessionStorage.getItem("info")));
    console.log(this.$route.params);
  },
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
  methods: {
    animate() {
      // let reg = /^1[0-9]{10}$/
      // let str = this.form.phone;
      // if(reg.test(str)){
      //     // this.$message.warning("请认真填写");
      //  }else{
      //   this.$message.warning("请输入正确手机号");
      //  }
      var sss = this.form.phone.replace(/[^\d]/g, "");
      if (sss == 1) {
        this.$message.warning("请输入数字");
      } else {
        this.form.phone = this.form.phone.replace(/[^\d]/g, "");
      }
    },
    sqjd() {
      console.log(111111);
      if (this.form.xlp) {
        let option = {
          url: "/api/" + "Mark" + "/" + "getMarkList",
          method: "post",
          data: {
            mark: this.form.xlp,
            hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          },
        };
        this.$http(option).then((res) => {
          this.ch2 = res.data.data.lists.length;
          if (res.data.data.lists.length == 0) {
            this.$message.warning("号码牌不存在");
            this.disableds = true;
          } else {
            let option = {
              url: "/api/" + "Luggage" + "/" + "getMark",
              method: "post",
              data: {
                mark: this.form.xlp,
                hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
              },
            };
            this.$http(option).then((res) => {
              this.ch1 = res.data.data.stat;
              if (res.data.data.stat == 0) {
                this.newarr.push(this.form.xlp);
                this.disableds = false;
              } else {
                this.$message.warning(res.data.data.msg);
                this.disableds = true;
              }
            });
          }
        });
      }
    },
    sqjd1(info) {
      if (info.xlpnum) {
        this.$nextTick(() => {
          let option = {
            url: "/api/" + "Mark" + "/" + "getMarkList",
            method: "post",
            data: {
              mark: info.xlpnum,
              hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
            },
          };
          this.$http(option).then((res) => {
            this.ch2 = res.data.data.lists.length;
            if (res.data.data.lists.length == 0) {
              this.$message.warning("号码牌不存在");
              this.disableds = true;
            } else {
              let option = {
                url: "/api/" + "Luggage" + "/" + "getMark",
                method: "post",
                data: {
                  mark: info.xlpnum,
                  hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
                },
              };
              this.$http(option).then((res) => {
                this.ch1 = res.data.data.stat;
                if (res.data.data.stat == 0) {
                  this.disableds = false;
                  this.newarr.push(info.xlpnum);
                } else {
                  
                  this.$message.warning("重复号码牌请重新填写");
                  this.disableds = true;
                }
              });
            }
          });
        });
      }
    },
    yzm() {
      //随机生成验证码
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
      for (let i = 0; i < 6; i++) {
        let code = random[Math.floor(Math.random() * 10)];
        this.yzmcode += code;
      }
    },
    delinput(index) {
      this.addinput.splice(index, 1);
    },
    bh(e) {
      if (this.form.xlp) {
        this.garyor = false;
        this.disabled = false;
      } else {
        this.garyor = true;
        this.disabled = true;
      }
    },
    addmark() {
      this.addinput.push({
        xlp: "行李牌",
        xlpnum: "",
      });
    },
    success() {
      if (this.form.xlp && this.form.name && this.form.phone) {
        if (this.$route.params.id == 1) {
          console.log(this.ch1, this.ch2);

          if (this.ch1 == 0 && this.ch2 != 0) {
            this.defa(0);
          }
        }
        if (this.$route.params.id == 2) {
          if (this.ch1 == 0 && this.ch2 != 0) {
            this.defa(1);
          }
        }
        if (this.$route.params.id == 3) {
          if (this.ch1 == 0 && this.ch2 != 0) {
          
            var strnum = this.newarr.join(",");
            let option = {
              url: "/api/" + "Expressage" + "/" + "setExpressageWeb",
              method: "post",
              data: {
                hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
                userName: this.form.name,
                phone: this.form.phone,
                homeNum: this.form.room,
                mark: strnum,
                createDate: this.$time,
                type: 1,
                residue: "",
                hotelName: JSON.parse(sessionStorage.getItem("info")).name,
              },
            };
            this.$http(option).then((res) => {
              this.$router.push({
                name: "jdsuccess",
                params: {
                  sz: 2,
                  names: "存件成功",
                  infos: res.data.data,
                },
              });
            });
          }
        }
      } else {
        this.$message.warning("不能为空");
      }
    },
    defa(num) {

      this.yzm();
      var strnum = this.newarr.join(",");
      let option = {
        url: "/api/" + "Luggage" + "/" + "setLuggageWeb",
        method: "post",
        data: {
          hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          userName: this.form.name,
          phone: this.form.phone,
          homeNum: this.form.room,
          mark: strnum,
          createDate: this.$time,
          type: 1,
          residue: "",
          isCare: num,
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
        },
      };
      this.$http(option).then((res) => {
        this.$router.push({
          name: "jdsuccess",
          params: {
            sz: num,
            names: "存件成功",
            infos: res.data.data,
          },
        });
      });
    },
    back() {
      if (this.$route.params.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    qx() {
      if (this.$route.params.id == 1) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.$route.params.id == 2) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.$route.params.id == 3) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.gary {
  background: gray !important;
  border: gray;
}
</style>