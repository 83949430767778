import axios from "axios";
import { blobValidate } from "@/http";
import { saveAs } from "file-saver";
export default {
    name(name,urls, isDelete = true) {
        var url=process.env.VUE_APP_URL+urls
        axios({
            method: "post",
            url: url,
            responseType: "blob",
            data: name,
            headers: { token: sessionStorage.getItem("token") },
        }).then(async (res) => {
            const isLogin = await blobValidate(res.data);
            if (isLogin) {
                const blob = new Blob([res.data]);
                console.log(res.headers);
                let h = res.headers["content-disposition"]
                    .split(";")[1]
                    .split("filename=")[1];
                h = h.replace(new RegExp('"', "g"), "");
                console.log(res.headers);
                console.log(h);
                this.saveAs(blob, decodeURI(h));
            } else {
                this.printErrMsg(res.data);
            }
        });
    },
    saveAs(text, name, opts) {
        saveAs(text, name, opts);
    },

}
