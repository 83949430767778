<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 行李牌列表/添加行李牌 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note"> 牌号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button type="danger" @click="hmp" v-preventReClick> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
    <div id="qrcode" ref="qrCodeUrl" style="display: none"></div>
    <!-- <img :src="iurl"> -->
  </div>
</template>
<script>
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      form: { name: "" },
      iurl: "",
    };
  },
  methods: {
    aa() {},
    hmp() {
      let timeout;
      console.log(this.form.name.length);
      if (this.form.name.length < 3) {
        this.$message.error("行李牌最少3位");
        return;
      }

      if (this.form.name) {
        let option = {
          url: "/api/" + "Mark" + "/" + "getMarkList",
          method: "post",
          data: {
            mark: this.form.name,
            hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          },
        };
        this.$http(option).then((res) => {
          if (!res.data.data.lists.length) {
            if (this.form.name) {
              this.$nextTick(() => {
                document.getElementById("qrcode").innerHTML = "";
                let qrcode = new QRCode(this.$refs.qrCodeUrl, {
                  width: 280,
                  height: 280,
                  text: this.form.name,
                  render: "canvas",
                  colorDark: "#000",
                  colorLight: "#fff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
                let canvas = qrcode._el.childNodes[0];
                let context = canvas.getContext('2d')
                // let img = qrcode._el.childNodes[1];

                //获取画布的宽和高
                let width=canvas.getAttribute("width")
                let height=canvas.getAttribute("height");

                //获取画布的图像信息,一个副本
                let data=context.getImageData(0,0,width,height)

                //重新设置画布的大小
                width=parseInt(width)+20
                height=parseInt(height)+20
                canvas.setAttribute("width",width)
                canvas.setAttribute("height",height)
                context.fillStyle="#fff";
                context.fillRect(0,0,300,300);
                context.putImageData(data,10,10)

                this.iurl = canvas.toDataURL();
                this.addhmp(this.iurl);
              });
            }
          } else {
            alert("重复号码牌");
          }
        });
      }
    },
    addhmp(iurl) {
      let option = {
        url: "/api/" + "Mark" + "/" + "setMark",
        method: "post",
        data: {
          mark: this.form.name,
          creatDate: this.$time,
          hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
          qrCode: iurl,
        },
      };
       console.log(option);
      this.$http(option).then((res) => {
       
        this.$router.push("/jdhome/jdxllist");
      });
    },
    back() {
      this.$router.push("/jdhome/jdxllist");
    },
    qx() {
      this.$router.push("/jdhome/jdxllist");
    },
    base64ImgtoFile() {},
    // 绘制二维码，绘制边框+生成的二维码+logo
    drawQrcode() {
      const image = new Image();
      // 解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous');
      
      // 图片加载完成后，通过canvas处理
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      // qrcodejs2 生成的二维码加载完成后，开始 canvas 绘制
      image.onload = function() {
        // 通过canvas绘制，canvas的宽高设置为二维码图片宽高+10px，宽/高多的10px = 左/上边框宽度1px + 左/上间距4px + 右/下间距4px + 右/下边框宽度1px
        canvas.width = image.width + 10;
        canvas.height = image.height + 10;
        // canvas 绘制线条的宽度设置为1px，线条的颜色设置为 #e5e5e6 
        context.lineWidth = 2;
        context.strokeStyle = "#e5e5e6";
        /*
          globalCompositeOperation 属性设置或返回如何将一个源（新的）图像绘制到目标（已有的）的图像上。
          源图像 = 您打算放置到画布上的绘图。
          目标图像 = 您已经放置在画布上的绘图。
          source-over：默认。在目标图像上显示源图像。
        */ 
        context.globalCompositeOperation = "source-over";
        context.roundRect(0, 0, 100, 100, 4); // 绘制圆角矩形的范围
        context.stroke();
        // 圆角矩形的范围填充白色
          context.fillStyle ="rgba(255,255,255,1)";
          context.fill();
        // padding:4px + border:1px，4 + 1 = 5
        context.drawImage(image,5, 5, image.width, image.height); // 目标图像
        context.roundRect(0, 0, 100, 100, 4);// 源图像，重新在canvas上绘制圆角边框的线，drawImage的时候被覆盖掉了
        context.stroke();
      }
      // 获取二维码qrimg的src，赋值给image，触发image的onload方法
      let qrimg = this.$refs['qrCodeDiv'].querySelector('img');
      image.src = qrimg.src;
      console.log(qrimg.src);
    },
    // 下载二维码图片
    downloadImg(){
      // 先用canvas绘制二维码图片
      this.drawQrcode((url) => {
        // 利用 a 标签的 download 属性去下载图片
        let a = document.createElement('a');
        // 下载图片的名称设置为“配置服务器二维码”
        a.download = "配置服务器二维码";
        a.href = url;
        // 创建一个点击事件
        const clickEvent = new MouseEvent('click');
        // 触发 a 标签的点击事件
        a.dispatchEvent(clickEvent);
      });
    },
    // 生成二维码
    createQrcode() {
      new QRcode(this.$refs['qrCodeDiv'],{
        text: "", // 扫二维码后获得的信息
        width: 200, // 图片宽90px，左右padding各4px，边框各1px， 100-8px-2px
        height: 200, // 图片高90px，上下padding各4px，边框各1px， 100-8px-2px
      })
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>