<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 订单管理/客户信息录入 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <a-row class="top"> 扫码取件 </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 取件码 </a-col>
          <a-col :span="4">
            <a-input v-model="form.qjcode" placeholder="请用扫码枪扫一扫" />
          </a-col>
        </a-row>
      </div>
      <a-row class="top"> 客户录入信息取件 </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 姓名 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 身份证号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.sfz" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 取件备注 </a-col>
          <a-col :span="4">
            <a-input v-model="form.remark" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button type="danger" @click="rej"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        qjcode: "",
        name: "",
        sfz: "",
      },
      sfzid: "",
      getinfo: {},
    };
  },
  mounted() {
    this.sfzid = this.$route.params.id;
    this.getinfo = this.$route.params.infos;
  },
  methods: {
    rej() {
      //取件码取件
      if (this.form.qjcode) {
        let str = {
          url: "/api/" + "Luggage" + "/" + "takeLuggage ",
          method: "post",
          data: {
            id: this.getinfo.id,
            qjCode: this.form.qjcode,
           
          },
        };
        this.$http(str).then(res=>{
          if(res.data.code==1){
            this.$router.push({name:'jdcg',params:{names:'取件成功',infos:this.$route.params}});

          }else{
             this.$message.error(res.data.msg);
          }
           
        });
        return;
      }
      if(this.form.name&&this.form.sfz){
        console.log(1111)
         let str = {
          url: "/api/" + "Luggage" + "/" + "takeLuggage ",
          method: "post",
          data: {
            id: this.getinfo.id,
            name: this.form.name,
            identity: this.form.sfz,
            remark: this.form.remark,
          },
        };
        this.$http(str).then(res=>{
          if(res.data.code==1){
            this.$router.push({name:'jdcg',params:{names:'取件成功',infos:this.$route.params}});

          }else{
             this.$message.error(res.data.msg);
          }
           
        });
        return;
      }

      // if(this.form.name && this.form.sfz){
      //    if (this.sfzid == 10) {
      //   this.pubilc()
      // }
      // if (this.sfzid == 11) {
      //   this.pubilc()
      // }
      // if (this.sfzid == 12) {
      //   let option = {
      //   url: "/api/" + "Expressage" + "/" + "updateExpressages",
      //   method: "post",
      //   data: {
      //     id:this.getinfo.id,
      //     consignee:'',
      //     qjPhone:'',
      //     way:2,
      //     qjName:this.form.name,
      //     identity:this.form.sfz,
      //     updateDate:this.$time
      //   },
      // };
      // this.$http(option).then((res) => {
      //      this.$router.push({name:'jdcg',params:{names:'取件成功',infos:this.$route.params}});
      // });
      // }
      // }
    },
    pubilc() {
      let option = {
        url: "/api/" + "Luggage" + "/" + "updateLuggages",
        method: "post",
        data: {
          id: this.getinfo.id,
          consignee: "",
          qjPhone: "",
          way: 2,
          qjName: this.form.name,
          identity: this.form.sfz,
          updateDate: this.$time,
        },
      };
      this.$http(option).then((res) => {
        this.$router.push({
          name: "jdcg",
          params: { names: "取件成功", infos: this.$route.params },
        });
      });
    },
    back() {
      if (this.sfzid == 10) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.sfzid == 11) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.sfzid == 12) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    qx() {
      if (this.sfzid == 10) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.sfzid == 11) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.sfzid == 12) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>