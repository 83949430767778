<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text" style="width:12%"> 短信管理/短信设置 </a-col>
        <a-col :span="1" class="top-back"> <a-button @click="back">返回</a-button> </a-col>
      </a-row>
      <div class="top">
           <a-row>
               <a-col :span="1" class="note" style="width:7%">
                    每条短信
               </a-col>
               <a-col :span="3">
                   <a-input v-model="form.name" />
               </a-col>
               <a-col :span="1" class="note" style="margin-left:5px">
                   元
               </a-col>
           </a-row>
      </div>
      <div class="top">
      <a-button type="danger" @click="confirm">
      确认
    </a-button>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name:''
      },
      num:0,
      setid:''
    };
  },
  mounted(){
     let option ={
          url:'/api/'+'Rule'+'/'+'getMoney',
          method:'post',
      }
      this.$http(option).then(res=>{
         if(res.data.data.lists.length == 0){
            this.num = 0
        }else{
            this.form.name = res.data.data.lists[0].money
            this.setid = res.data.data.lists[0].id
            this.num = 1
        }
      })
  },
  methods:{

    confirm(){
      if(this.num==0){
       let option ={
          url:'/api/'+'Rule'+'/'+'setMoney',
          method:'post',
          data:{
            money:this.form.name
          }
      }
      this.$http(option).then(res=>{
          this.$router.push('/home/money')
      })
      }else{
        let option ={
          url:'/api/'+'Rule'+'/'+'updateMoney',
          method:'post',
          data:{
            money:this.form.name,
            id:this.setid
          }
      }
      this.$http(option).then(res=>{
          this.$router.push('/home/money')
      })
      }
     
    },
      back(){
        this.$router.push('/home/money')
      }
  }
};
</script>
<style scoped>
.note{
    line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
   margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd{
    display: flex;
    justify-content: space-between;
}
.edd div:hover{
    cursor: pointer;
}
.top-r{
    position: relative;
    right: -87%;
}
.top-flex{
    width: 40%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 38px;
}
</style>