<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 广告列表/{{ adds }} </a-col>
        <a-col :span="1" class="top-r">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%">
            <span style="color: red">*</span>标题
          </a-col>
          <a-col :span="4">
            <a-input v-model="form.title" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 跳转链接 </a-col>

          <a-col :span="3">
            <a-select
              :size="size"
              style="width: 150px"
              @change="handleChanges"
              @select="select"
              :value="types"
            >
              <a-select-option :value="item.id" v-for="item in lianjie" :key="item.id">
                {{ item.tatle }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- <a-col :span="4">
            <a-input v-model="form.link" placeholder="请输入" />
          </a-col> -->
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%">
            <span style="color: red">*</span>封面图</a-col
          >
          <a-col :span="4">
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="https://api.miaocun.net/api/rule/upload"
              :headers=headers
              :before-upload="beforeUpload"
              @change="handleChange"
              :remove="remove"
            >
              <img v-if="form.img" :src="form.img" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-col>
          <a-col>
            <div style="color:red">
              提示：建议轮播图上传大小300*150
            </div>
            <div style="color:red">
              提示：建议开屏广告上传大小750*1334
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="top" v-if="types=='轮播图'">
        <a-row>
          <a-col :span="1" class="note" style="width: 84px"> 内容 </a-col>
          <a-col :span="22">
            <quill-editor
              class="edit_container"
              ref="myQuillEditor"
              v-model="content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            />
          </a-col>
        </a-row>
      </div>

      <div class="tops">
        <a-button type="danger" @click="add" v-preventReClick> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="result"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
       hh:'',
      headers:{
        token:sessionStorage.getItem('token')

      },
      id: "",

      addf: true,
      adds: "添加",
      loading: false,
    

      size: "default",
      lianjie: [
        {
          id: "",
          tatle: "请选择",
        },
        {
          id: 1,
          tatle: "轮播图",
        },
        {
          id: 2,
          tatle: "开屏广告",
        },
      ],
      form: {
        title: "",
        type: "",
        img: "",
        content:"",
      },
       content: "",
      types: "",
       editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
          ],
        },
        placeholder: "请输入内容及工作量",
      },
    };
  },
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    
    if (this.$route.params.infos) {
      this.adds = "编辑";
       let { title, id, type, img, link,content } = this.$route.params.infos;
    console.log(this.$route.params, type);
    this.form.title = title;
    this.form.type=type
    if(type==1){
     this.types="轮播图";
     console.log(this.types)
    }else if(type==2){
     this.types="开屏广告";
     console.log(this.types)
    }
   
    this.form.link = link;
    this.form.img = img;
    this.content=content
    this.id = id;
    }
   
  },
  methods: {
     onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur(e) {},
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(e) {
      // console.log(e.text)
      this.hh = e.text
      this.form.content = this.content;
    }, // 内容改变事件
    add() {
      let option = {
        url: "/api/" + "Advertise" + "/" + "add",
        method: "post",
        data: this.form,
      };
      if (this.adds == "编辑") {
          this.form.id=this.id
        option = {
          url: "/api/" + "Advertise" + "/" + "edit",
          method: "post",
          data:this.form,
        };
      }

      console.log(this.form);
      if (
        this.form.title &&

        this.form.type &&
        this.form.img
      ) {
        this.$http(option).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
               this.$message.warning("操作成功");
            this.$router.go(-1);
          } else {
            this.$message.warning(res.data.msg);

          }
        });
        console.log(option);
      } else {
        this.$message.warning("请认真填写");
      }
    },
    result() {
      this.$router.go(-1);
    },
    back() {
      this.$router.go(-1);
    },
    //上传文件

    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        this.form.img = info.file.response.data.headUrl;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    handleChanges(value) {
      // console.log(value)
      this.form.type = value;
       if(value==1){
     this.types="轮播图";
     console.log(this.types)
    }else if(value==2){
     this.types="开屏广告";
     console.log(this.types)
    }
      
    },
    select(value){
console.log(value)
    },

    remove(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 10px;
}
.tops {
  width: 95%;
  margin: 0 auto;
  margin-top: 80px;
 
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -77%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.edit_container {
  height: 200px;
}
img{
  width: 200px;
  height: 200px;
}
</style>