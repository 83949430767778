<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="3" class="text"> 订单列表/变更状态 </a-col>
        <a-col :span="1" class="top-r">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note"> 备注 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>

      <div class="top">
        <a-button type="danger" @click="qr"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="result"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: { name: "" },
      paramsid: "",
      getinfo: {},
    };
  },
  mounted() {
    this.paramsid = this.$route.params.id;
    this.getinfo = this.$route.params.infos;
  },
  methods: {
    qr() {
      if (this.form.name) {
        if (this.paramsid == 4) {
          this.pulic();
        }
        if (this.paramsid == 5) {
          this.pulic();
        }
        if (this.paramsid == 6) {
          let option = {
            url: "/api/" + "Expressage" + "/" + "updateExpressageWeb",
            method: "post",
            data: {
              id: this.getinfo.id,
              remark: this.form.name,
              updateDate: this.$time,
            },
          };
          this.$http(option).then((res) => {
            this.$router.push("/jdhome/jdkd");
          });
        }
      }
    },
    pulic() {
      let option = {
        url: "/api/" + "Luggage" + "/" + "updateLuggageWeb",
        method: "post",
        data: {
          id: this.getinfo.id,
          remark: this.form.name,
          updateDate: this.$time,
        },
      };
      this.$http(option).then((res) => {
        if (this.$route.params.id == 4) {
          this.$router.push("/jdhome/jdjc");
        }
        if (this.$route.params.id == 5) {
          this.$router.push("/jdhome/jdzj");
        }
      });
    },
    result(){
       if (this.paramsid == 4) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.paramsid == 5) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.paramsid == 6) {
        this.$router.push("/jdhome/jdkd");
      }
    },
    back() {
      if (this.paramsid == 4) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.paramsid == 5) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.paramsid == 6) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>