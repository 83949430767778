<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 店员列表/添加店员 </a-col>
        <a-col :span="1" class="top-rrr"> <a-button @click="back">返回</a-button> </a-col>
      </a-row>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 姓名 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 登陆账号 </a-col>
          <a-col :span="4">
            <a-input v-model="form.account" placeholder="请输入" />
            
          </a-col>
          <a-col :span="4">
            
            <p style="color:red;">提示：建议使用手机号</p>
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 登陆密码 </a-col>
          <a-col :span="4">
            <a-input type="password" v-model="form.pass" placeholder="请输入" />
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%;"> 员工头像 </a-col>
          <a-col :span="4">
            <label for="male">
              <div class="yuan">
                <img :src="imageUrl" style="width: 100%;height:100%" />
              </div>
              <input
                type="file"
                id="male"
                @change="fileChange"
                style="display: none"
              />
            </label>
               <!-- <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="https://api.miaocun.net/api/rule/upload"
              :headers=headers
              :before-upload="beforeUpload"
              @change="handleChange"
              :remove="remove"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload> -->
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-button type="danger" @click="qr"> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  data() {
    return {
      form: {
        name: "",
        account: "",
        pass: "",
      },
       headers:{
        token:sessionStorage.getItem('token')

      },
      loading: false,
      imageUrl: "",
      upimg: "",
    };
  },
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
  methods: {
    fileChange(e) {
      var file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    back(){
       this.$router.push("/jdhome/jdperson");
    },
    qr() {
      if (
        this.form.name &&
        this.form.account &&
        this.form.pass &&
        this.imageUrl
      ) {
        let option = {
          url: "/api/" + "User" + "/" + "getUsersc",
          method: "post",
          data: {
            hotelName:JSON.parse(sessionStorage.getItem("info")).name,
            account: this.form.account,
          },
        };
        this.$http(option).then((res) => {
          
          if (!res.data.data.lists.length) {
            if (
              this.form &&
              this.form.pass &&
              this.form.account &&
              this.imageUrl
            ) {
              let option = {
                url: "/api/" + "User" + "/" + "setHotelUser",
                method: "post",
                data: {
                  userName: this.form.name,
                  passWord: this.form.pass,
                  account: this.form.account,
                  creatDate: this.$time,
                  headUrl: this.imageUrl.split(",")[1],
                  hotelId: JSON.parse(sessionStorage.getItem("info")).id,
                  hotelName: JSON.parse(sessionStorage.getItem("info")).name,
                },
              };
              this.$http(option).then((res) => {
                if(res.data.code==1){
                  this.$router.push("/jdhome/jdperson");

                }else{
                  this.$message.success(res.data.msg);
                }

              
                
              });
            }
          } else {
            alert("账号已存在");
          }
        });
      }else {
            alert("不能为空");
          }
    },
    qx() {
     this.$router.push("/jdhome/jdperson");
    },
     beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange(info) {
      console.log(info);
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        this.imageUrl = info.file.response.data.headUrl;
        this.loading = false;
      }
    },
    remove(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -87%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.yuan {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid #d9d9d9;
}
</style>