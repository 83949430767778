<template>
  <div class="caozuorizhi">
 <div style="overflow: auto;" class="top">
    <a-row class="top"><a-col :span="4" class="text" style="width: 14%">
        酒店管理/酒店日志列表
        </a-col></a-row>
      <a-row class="top">

        <a-col :span="3" class="top-l">
          <a-input
            placeholder="请输入酒店名称"
            style="width: 200px"
            v-model="text"
          />
        </a-col>
        <a-col :span="1" class="top-ll"><a-button @click="cx">查询</a-button> </a-col>
        <a-col :span="1" class="top-e"> <a-button @click="result">重置</a-button> </a-col>
      </a-row>
       <div class="top">
          <a-table bordered :loading="loading" :columns="columns" :data-source="data" :rowKey="(record, index) => {return index;}">
          </a-table>
      </div>
     
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
         text:'',//搜索框
          data:[],
          loading:false,
        
          columns:[
            {
                title: '序号',
                dataIndex: 'id'
            },{
                title: '操作时间',
                dataIndex: 'create_time'
            },{
                title: '操作名称',
                dataIndex: 'name'
            },{
                title: '操作内容',
                dataIndex: 'content'
            },{
                title: '操作平台',
                dataIndex: 'type'
            },{
                title: '操作人员',
                dataIndex: 'create_by_name'
            },{
                title: '人员账号',
               dataIndex: 'create_by_account'
            },
            {
                title: '酒店编号',
               dataIndex: 'hotel_id'
            },
            {
                title: '酒店名称',
               dataIndex: 'hotel_name'
            },
            {
                title: '酒店地址',
               dataIndex: 'hotel_address'
            }
        ],
    };
  },
  mounted() {
    this.defaultList()
  },
  methods: {
    //默认列表
      defaultList(){
        this.loading=true
          let option ={
              url:'/api/'+'Luggage'+'/'+'logList',
              method:'post',
              data:{
                name:"",
                page:1,
                offset:200,

              }
          }
          this.$http(option).then(res=>{
              this.loading=false
              this.data = res.data.data
          })
      },
    //搜索
      cx(){
        if(this.text){
           let option ={
              url:'/api/'+'Luggage'+'/'+'logList',
              method:'post',
              data:{
                name:this.text,
                 page:1,
                offset:200,
              }
          }
          this.$http(option).then(res=>{
              this.data = res.data.data
              
          })
        }else{
          this.defaultList()
        }
        
      },
       //重置
      result(){
         this.text = ''
      }
  }
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin: 0 20px;
}
.top-e {
  margin-right: 20px;
}
</style>