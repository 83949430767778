<template>
  <div>
    <div class="top" style="overflow: auto">
      <a-row class="top"
        ><a-col :span="4" class="text" style="width: 20%">
          订单管理/行李转交订单列表
        </a-col>
        <a-col :span="2" v-if="userinfo.identity == 1">
          <a-button @click="exportt">导出</a-button>
        </a-col></a-row
      >
      <a-row class="top">
        <a-col :span="2" class="top-l">
          <a-input placeholder="姓名" v-model="lists.name" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="房间号" v-model="lists.room" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="手机号" v-model="lists.tel" />
        </a-col>
        <a-col :span="3" class="top-l">
          <a-date-picker
            style="width: 100%"
            @change="onChange"
            :value="lists.time"
            placeholder="选择日期"
          />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-select
            placeholder="超时物品"
            allowClear
            :value="lists.overtime"
            @change="ChangeTime"
            style="width: 100%"
          >
            <a-select-option
              :value="index"
              v-for="(item, index) in overtime"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2" class="top-l">
          <a-select
            placeholder="寄存状态"
            allowClear
            :value="lists.state"
            style="width: 100%"
            @change="ChangeState"
          >
            <a-select-option
              :value="index"
              v-for="(item, index) in xzstate"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="订单编号" v-model="lists.num" />
        </a-col>
        <a-col :span="1" class="top-l">
          <a-button @click="about">查询</a-button>
        </a-col>
        <a-col :span="1" class="top-rr">
          <a-button @click="result">重置</a-button>
        </a-col>
      </a-row>
      <div class="top">
        <div class="but" @click="establish">创建订单</div>
      </div>
      <div class="top">
        <a-table
          bordered
          :loading="loading"
          :columns="columns"
          :data-source="data"
          :scroll="{ x: 1500 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="mark" slot-scope="record">
            <span v-for="(item, index) in record.qj" :key="item.id"
              >{{ item.mark
              }}{{ index >= record.qj.length - 1 ? "" : "," }}</span
            >
          </template>
          <template slot="state" slot-scope="record">
            {{ record.state | stateval }}
          </template>
          <template slot="timeout" slot-scope="record">
            {{ record | timeout }}
          </template>
          <template slot="creat" slot-scope="record">
            {{ record.createDate }}
          </template>
          <template slot="type" slot-scope="record">
            {{ record.type | typeval }}
          </template>
          <template slot="way" slot-scope="record">
            {{ record.qj | wayval }}
          </template>
          <template slot="pickupRemark" slot-scope="record">
            <span style="color: #2680EB;cursor:pointer" @click="addDeta(record.id)">详情</span>
          </template>
          <template slot="action" slot-scope="record">
            <a-row type="flex" justify="space-between">
              <a-col :span="6">
                <div v-if="record.state == 1" @click="qrqj(record)" class="xs">
                  确认取件
                </div>
                <div v-else>---</div>
              </a-col>
              <a-col :span="6">
                <div
                  v-if="record.state == 1"
                  @click="bgstate(record)"
                  class="xs"
                >
                  变更状态
                </div>
                <div v-else>---</div>
              </a-col>
              <!-- <a-col :span="3">
                <div v-if="record.state == 1" @click="edit(record)" class="xs">
                  编辑
                </div>
                <div v-else>---</div>
              </a-col> -->
              <!-- <a-col :span="3">
                <div @click="del(record)" class="xs">删除</div>
              </a-col> -->
              <a-col :span="6">
                <div v-if="record.state == 1" @click="dx(record)" class="xs">
                  短信提醒
                </div>
                <div v-else>---</div>
              </a-col>
            </a-row>
          </template>
          <template slot="qjUpdatetime" v-if="record.state == 2" slot-scope="record">
            {{record.qj[0].update_time}}
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="visible"
      :footer="null"
      :maskClosable="false"
      width="300px"
      centered
      class="model-div"
    >
      <div @click="shmqj" v-if="infotoggle">扫货码取件</div>
      <!-- <div @click="saoyisao" v-if="ewmtoggle">扫码客户二维码</div> -->
      <div @click="information" v-if="ewmtoggle">客户信息录入</div>
      <div @click="khsflr" v-if="infotoggle">客户身份信息录入</div>
    </a-modal>
    <a-modal v-model="detaShow" title="详情" @ok="detaShow = false" okText="我知道了">
      <div v-for="(item, index) in Info" :key="index">
        <p>修改人：{{ item.username }}</p>
        <p>内容：{{ item.content }}</p>
        <p>修改时间：{{ item.create_time }}</p>
      </div>
      <a-input placeholder="新纪录" v-model="jilu" />
      <a-button style="margin-top:20px" @click="addLog">添加记录</a-button>
    </a-modal>
  </div>
</template>
<script>
import download from "@/http/download";
export default {
  data() {
    return {
      detaShow: false,
      Info:[],//详情
      overtime: ["未超时", "已超时"],
      xzstate: ["待寄存", "寄存中", "已完成", "已取消"],
      visible: false,
      ewmtoggle: true,
      userinfo: JSON.parse(sessionStorage.getItem("info")),
      infotoggle: true,
      lists: {
        name: "",
        room: "",
        time: "",
        overtime: undefined,
        state: undefined,
        num: "",
        tel: "",
      },
      loading: false,
      strt: "",
      columns: [
        {
          title: "订单编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "createDate",
          width: 150,
        },
        {
          title: "房间号",
          dataIndex: "homeNum",
          width: 80,
        },
        {
          title: "号牌",
          scopedSlots: { customRender: "mark" },
          width: 100,
        },
        {
          title: "存件人姓名",
          dataIndex: "userName",
          width: 120,
        },
        {
          title: "存件人手机号",
          dataIndex: "phone",
          width: 150,
        },
        {
          title: "寄存时间",
          width: 150,
          scopedSlots: { customRender: "creat" },
        },
        {
          title: "寄存状态",
          width: 90,
          scopedSlots: { customRender: "state" },
        },
        {
          title: "寄存方式",
          width: 120,
          scopedSlots: { customRender: "type" },
        },
        {
          width: 150,
          title: "存件备注",
          dataIndex: "remark",
        },
        {
          title: "物品超时",
          width: 90,
          scopedSlots: { customRender: "timeout" },
        },
        {
          title: "取件人姓名",
          dataIndex: "qjName",
          width: 110,
        },
        {
          title: "取件人手机号",
          dataIndex: 'qjPhone',
          width: 120,
        },
        {
          title: "取件人信息",
          dataIndex: "identity",
          width: 170,
        },
        {
          width: 170,
          title: "取件时间",
          scopedSlots: {
            customRender: "qjUpdatetime",
          },
        },
        {
          width: 150,
          title: "取件备注",
          scopedSlots: { customRender: "pickupRemark" },
        },

        {
          title: "取件方式",
          width: 140,
          scopedSlots: { customRender: "way" },
        },

        {
          title: "操作",
          width: 300,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      data: [],
      bjinfo: {},
      jilu:'',
      jiluID:''
    };
  },
  mounted() {
    this.defaultList();
  },
  methods: {
    addLog() {
      let option = {
        url: `/api/luggage/addPartLog?id=${this.jiluID}&type=1&content=${this.jilu}`,
        method: "get",
      };
      this.$http(option).then((res) => {
        this.$message.success(res.data.msg);
        // 刷新
        this.addDeta(this.jiluID)
      });
      // 清空
      this.jilu = ''
    },
    addDeta(id) {
      this.jiluID = id
      let option = {
        url: `/api/luggage/partLogList?id=${id}&type=1`,
        method: "get",
      };
      this.$http(option).then((res) => {
        // console.log(res)
        this.detaShow = true;
        this.Info = res.data.data.info
      });
    },
    //默认列表
    defaultList() {
      this.loading = true;
      let option = {
        url: "/api/" + "Luggage" + "/" + "getLuggageList",
        method: "post",
        data: {
          isCare: 1,
          userName: this.lists.name,
          homeNum: this.lists.room,
          createDate: this.strt,
          timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
          state: this.lists.state == undefined ? "" : this.lists.state,
          id: this.lists.num,
          hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          mobile:this.lists.tel
        },
      };
      this.$http(option).then((res) => {
        this.loading = false;
        this.data = res.data.data.lists;
      });
    },
    edit(info) {
      this.$router.push({ name: "jdxtedit", params: { infos: info, id: 2 } });
    },
    establish() {
      // this.infotoggle = false;
      // this.ewmtoggle = true;
      // this.visible = true;
      this.$router.push({ name: "jdlrinfo", params: { id: 2 } });
    },
    saoyisao() {
      console.log("调取摄像头");
    },
    information() {
      this.$router.push({ name: "jdlrinfo", params: { id: 2 } });
    },
    qrqj(info) {
      // this.infotoggle = true;
      // this.ewmtoggle = false;
      // this.visible = true;
      // this.bjinfo = info
      this.$router.push({ name: "jdaddinfo", params: { infos: info, id: 11 } });
    },
    shmqj() {
      this.$router.push({
        name: "smzfym",
        params: { infos: this.bjinfo, id: 2 },
      });
    },
    khsflr() {
      this.$router.push({
        name: "jdaddinfo",
        params: { infos: this.bjinfo, id: 11 },
      });
    },
    bgstate(info) {
      this.$router.push({ name: "jdcopy", params: { infos: info, id: 5 } });
    },
    result() {
      (this.lists.name = ""),
        (this.lists.room = ""),
        (this.lists.time = ""),
        (this.lists.overtime = undefined),
        (this.lists.state = undefined),
        (this.lists.num = ""),
        (this.strt = ""),
        (this.lists.tel = "");
    },
    ChangeTime(value) {
      this.lists.overtime = value;
    },
    onChange(value, dateString) {
      this.strt = dateString;
      this.lists.time = value;
    },
    ChangeState(val) {
      this.lists.state = val;
    },
    about() {
      let option = {
        url: "/api/" + "Luggage" + "/" + "getLuggageList",
        method: "post",
        data: {
          isCare: 1,
          userName: this.lists.name,
          homeNum: this.lists.room,
          createDate: this.strt,
          timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
          state: this.lists.state == undefined ? "" : this.lists.state,
          id: this.lists.num,
          hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
          mobile:this.lists.tel
        },
      };
      this.$http(option).then((res) => {
        if(res.data.code == 0){
          this.$message.error('获取错误');
        }else{
          this.$message.success('获取成功');
          this.data = res.data.data.lists;
        }
      });
    },
    del(info) {
      let option = {
        url: "/api/" + "Luggage" + "/" + "delLuggage",
        method: "post",
        data: {
          id: info.id,
        },
      };
      this.$http(option).then((res) => {
        this.defaultList();
      });
    },
    dx(info) {
      let option = {
        url: "/api/" + "Luggage" + "/" + "cahoshi",
        method: "post",
        data: {
          id: info.id,
          residue: JSON.parse(sessionStorage.getItem("info")).residue,
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
          phone: info.phone,
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
        },
      };
      this.$http(option).then((res) => {
        this.$message.success("发送成功");
      });
    },
    //导出
    exportt() {
      // const name = "be756b96-c8b5-46c4-ab67-02e988973090.xlsx";

      // 默认下载方法
      let name = {
        isCare: 1,
        userName: this.lists.name,
        homeNum: this.lists.room,
        createDate: this.strt,
        timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
        state: this.lists.state == undefined ? "" : this.lists.state,
        id: this.lists.num,
        hotelId: JSON.parse(sessionStorage.getItem("info")).hotelId,
        hotelName: JSON.parse(sessionStorage.getItem("info")).name,
        mobile: this.lists.tel,
      };
      download.name(name, "api/luggage/export");
    },
  },
};
</script>
<style scoped>
.model-div div:first-child {
  margin-top: 30px;
}
.model-div div:last-child {
  margin-bottom: 10px;
}
.model-div div {
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.five {
  display: flex;
  justify-content: space-between;
}
</style>