<template>
  <div>

    <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
      <div class="img">
        <img src="../assets/v2_qlu5y4.png" class="imgw">
      </div>
      <div class="hotel">酒店行李寄存系统</div>
      <a-form-item>
        <a-input class="input" v-decorator="[
          'userName',
          { rules: [{ required: true, message: '请输入账号' }] },
        ]" placeholder="请输入账号">
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input class="input" v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码' }] },
        ]" type="password" placeholder="请输入密码">
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="login-form-button">
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  export default {
    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'normal_login'
      });
    },
    methods: {
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            let option = {
              url: '/api/' + 'Login' + '/' + 'login',
              method: 'post',
              data: {
                account: values.userName,
                password: values.password
              }
            }
            this.$http(option).then(res => {
              sessionStorage.setItem('info', JSON.stringify(res.data.data))
               sessionStorage.setItem('token', res.data.data.token)

              this.$store.commit('getinfo', JSON.stringify(res.data.data))
              if (res.data.msg == "登录成功!") {
                if (res.data.data.source == '0') {
                  this.$router.push('/home')
                } else {
                  this.$router.push('/jdhome')
                }
              } else {
                // this.$message.error(res.data.message);
              }
            })
          }
        });
      },
    },
  };
</script>
<style>
  #components-form-demo-normal-login {
    width: 300px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: rgba(230, 182, 102, 100) !important;
  }

  .ant-input-affix-wrapper .ant-input:focus {
    border: none;
    box-shadow: none;
    border: 1px solid white;
  }

  #components-form-demo-normal-login .login-form-button {
    width: 100%;
    background-color: rgba(230, 182, 102, 100);
    border: none;
  }

  .hotel {
    color: rgba(230, 182, 102, 100);
    text-align: center;
    font-size: 32px;
    margin: 20px 0;
  }

  .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .img .imgw {
    width: 100%;
  }
</style>