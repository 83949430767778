<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 充值列表/短信充值 </a-col>
        <a-col :span="1" class="top-rrr">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="top" v-for="(item, index) in pay" :key="index">
        1条短信价格:{{ item.money }}元
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 7%"> 充值条数 </a-col>
          <a-col :span="4">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-col>
          <a-col :span="4" style="color: red; line-height: 32px">
            &nbsp;&nbsp;提示：最低充值100条
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" style="width: 7%">支付方式</a-col>
          <a-col :span="4">
            <a-radio-group v-model="value" @change="onChange">
              <a-radio :value="1"> 支付宝 </a-radio>
              <a-radio :value="2"> 微信 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-button type="danger" @click="but" v-preventReClick> 确认 </a-button>
        <a-button style="margin-left: 20px" @click="qx"> 取消 </a-button>
      </div>
    </div>

    <a-modal
      v-model="visible"
      :footer="null"
      :maskClosable="false"
      width="350px"
      @cancel="xh"
      centered
      class="model-div"
    >
      <div class="pay-s">请使用{{ hz }}扫码充值支付</div>
      <div class="imgw">
        <div id="qrCode" ref="qrCodeDiv"></div>
      </div>
    </a-modal>

    <div v-html="conhtml" style="display:none"></div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      qctime: "",
      conhtml: "",
      yzm: "",
      pay: [],
      visible: false,
      form: { name: 100 },
      value: 1,
      hz: "支付宝",
    };
  },
  mounted() {
    this.short();
    
  },
  methods: {
    short() {
      let option = {
        url: "/api/" + "Rule" + "/" + "getMoney",
        method: "post",
      };
      this.$http(option).then((res) => {
        this.pay = res.data.data.lists;
      });
    },
    onChange(e) {
      this.value = e.target.value;
      if (this.value == 1) {
        this.hz = "支付宝";
      }
      if (this.value == 2) {
        this.hz = "微信";
      }
    },
    but() {
      console.log(this.value)
      if (this.form.name >= 1) {
        if (this.value == 1) {
          this.zfbpay();
        }
        if (this.value == 2) {
          this.visible = true;
          this.wxpay();
        }
      } else {
        this.$message.warning("条数大于等于100");
      }
    },
    mathnum() {
      //订单号
      var all = "0,1,2,3,4,5,6,7,8,9,a,b";
      var b = all.split(",");
      for (var i = 0; i < b.length; i++) {
        let n = Math.floor(Math.random() * b.length);
        this.yzm += b[n];
      }
    },
    wxpay() {
      var total = this.form.name * this.pay[0].money; 
      this.yzm = "";
      this.mathnum();
      let option = {
        url: "/api/" + "Zhifu" + "/" + "native",
        method: "post",
        data: {
          total: total, //金额
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,//酒店id
          residue: this.form.name,//条数
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,//酒店姓名
        },
      };
      this.$http(option).then((res) => {
        setTimeout(() => {
          this.$refs.qrCodeDiv.innerHTML = "";
          new QRCode(this.$refs.qrCodeDiv, {
            text: res.data.code_url,
            width: 280,
            height: 280,
            colorDark: "#333333",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.L,
          });
        }, 500);
        //轮询
        this.qctime = setInterval(() => {
          let option = {
            url: "/api/" + "Zhifu" + "/" + "lunxun",
            method: "post",
            data: {
              trade:  res.data.order_no,
            },
          };
          this.$http(option).then((res) => {
            // console.log(res.data)
            if (res.data.data.state == "1") {
              clearInterval(this.qctime);
              this.visible = false;
              this.$router.push("/jdhome/s");
            }
          });
        }, 1000);
      });
    },
    zfbpay() {
      var total = this.form.name * this.pay[0].money;
      this.yzm = "";
      this.mathnum();
      let option = {
        url: "/api/" + "Zhifu" + "/" + "zhifubao",
        method: "post",
        data: {
          total: total, //金额
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
          residue: this.form.name,//条数
          hotelName: JSON.parse(sessionStorage.getItem("info")).name,
        }
      };
      this.$http(option).then((res) => {
        
        this.conhtml = res.data;
        this.$nextTick(() => {
          document.forms["alipaysubmit"].submit();
        });
       
      });
    },
    back() {
      this.$router.push("/jdhome/jdmoney");
    },
    qx() {
      this.$router.push("/jdhome/jdmoney");
    },
    xh() {
      clearInterval(this.qctime);
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -83%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.imgw {
  width: 300px;
  height: 300px;
  padding: 10px;
}
.pay-s {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>