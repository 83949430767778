<template>
  <div>
    <div>
      <a-row class="top">
        <a-col :span="4" class="text"> 订单列表/取件成功 </a-col>
        <a-col :span="1" class="top-r">
          <a-button @click="back">返回</a-button>
        </a-col>
      </a-row>
      <div class="cen">
          <img  src="../../assets/cgg.png" width="100%"/>
          <div class="cc">取件成功</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      getinfo: {},
    };
  },
  mounted() {
    this.getinfo = this.$route.params.infos;
  },
  methods: {
    back() {
      console.log(this.getinfo.id)
      if (this.getinfo.id == 10) {
        this.$router.push("/jdhome/jdjc");
      }
      if (this.getinfo.id == 11) {
        this.$router.push("/jdhome/jdzj");
      }
      if (this.getinfo.id == 12) {
        this.$router.push("/jdhome/jdkd");
      }
    },
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.text {
  line-height: 32px;
}
.cen{
    width:200px;
    height: 250px;
    overflow: hidden;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cc{
    text-align: center;
    margin-top: 20px;
}
</style>
