<template>
  <div>
    <div style="overflow: auto;" class="top">
       <a-row class="top"><a-col :span="4" class="text" style="width: 14%">
        酒店管理/酒店列表
        </a-col></a-row>
      <a-row class="top">
      
        <a-col :span="3" class="top-l">
          <a-input
            placeholder="请输入酒店名称"
            style="width: 200px"
            v-model="text"
          />
        </a-col>
        <a-col :span="1" class="top-ll"><a-button @click="cx">查询</a-button> </a-col>
        <a-col :span="1" class="top-e"> <a-button @click="result">重置</a-button> </a-col>
      </a-row>
      <div class="top">
        <div class="but" @click="addjd">添加酒店</div>
      </div>
      <div class="top">
          <a-table bordered :loading="loading" :columns="columns" :data-source="data" :rowKey="(record, index) => {return index;}">
            <template slot="id" slot-scope="record">
               {{ record.id }}
            </template>
             <template slot="action" slot-scope="record">
                 <div class="edd">
                     <div @click="edit(record)">编辑</div>
                     <div @click="modelewm(record)">酒店二维码</div>
                     <div @click="del(record)">删除</div>
                 </div>
             </template>
          </a-table>
      </div>
    </div>
    <a-modal 
      v-model="visible" 
      :footer="null" 
      width='300px'
      centered
      :maskClosable=false >
        <div class="ewmstyle">
        <img :src="ewmurl" style="width:100%">
        </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
        text:'',
        visible:false,
        ewmurl:'',
        loading:false,
        columns:[
            {
                title: '酒店编号',
                scopedSlots: { customRender: 'id' }
            },{
                title: '酒店名称',
                dataIndex: 'name'
            },{
                title: '登陆账号',
                dataIndex: 'account'
            },{
                title: '酒店地址',
                dataIndex: 'address'
            },{
                title: '酒店电话',
                dataIndex: 'phone'
            },{
                title: '员工数量',
                dataIndex: 'employee'
            },{
                title: '操作',
                width: '20%',
                scopedSlots: { customRender: 'action' }
            }
        ],
        data:[]
    };
  },
  mounted(){
     this.defaultList()
  },
  methods:{
    //默认列表
      defaultList(){
        this.loading=true
          let option ={
              url:'/api/'+'Hotel'+'/'+'getHotels',
              method:'post',
             
          }
          this.$http(option).then(res=>{
            this.loading=false
              this.data = res.data.data.lists
          })
      },
      //二维码
      modelewm(info){
        console.log(info)
        this.ewmurl = info.qrCode
        this.visible = true
      },
     async del(info){
         let del = {
             url:'/api/'+'Hotel'+'/'+'delHotel',
             method:'post',
             data:{
               id:info.id
             }
         }
       let res = await this.$http(del)
       this.defaultList()
      },
      //搜索
      cx(){
        if(this.text){
           let option ={
              url:'/api/'+'Hotel'+'/'+'getHotel',
              method:'post',
              data:{
                name:this.text
              }
          }
          this.$http(option).then(res=>{
              this.data = res.data.data.lists
          })
        }else{
          this.defaultList()
        }
        
      },
      //编辑
      edit(info){
        this.$router.push({name:'editjd',params:{infos:info}})
      },
      addjd(){
        this.$router.push('/addjd')
      },
      //重置
      result(){
         this.text = ''
      }
  }
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin: 0 20px;
}
.top-e {
  margin-right: 20px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd{
    display: flex;
    justify-content: space-between;
}
.edd div:hover{
    cursor: pointer;
}
.ewmstyle{
  width: 260px;
  height: 260px;
  overflow: hidden;
  margin-top: 20px;
}
</style>