<template>
  <div>
    <a-row class="top"> 酒店二维码 </a-row>
    <div>
      <div class="imgw">
        <img id="girlImg" :src="iurl" />
        <div>
          <div class="login-form-button" @click="down">
            下载
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iurl: "",
      i:''
    };
  },
  mounted() {
    let info = JSON.parse(sessionStorage.getItem("info"));
    this.iurl = info.qrCode;
  },
  methods: {
    down() {
      //  var img = document.getElementById('girlImg'); // 获取要下载的图片
      //   var url = img.src;                            // 获取图片地址
        var a = document.createElement('a');          // 创建一个a节点插入的document
        var event = new MouseEvent('click')           // 模拟鼠标click点击事件
        a.download = 'beautifulGirl'                  // 设置a节点的download属性值
        a.href = this.iurl;                                 // 将图片的src赋值给a节点的href
        a.dispatchEvent(event)    
      
      console.log( this.iurl);
    } 
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.imgw {
  width: 270px;
  height: 330px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imgw img {
  width: 100%;
}
.login-form-button {
  background-color: rgba(230, 182, 102, 100);
  width: 90px;
  text-align: center;
  padding: 9px 0;
  border-radius: 5px;
  color: white;
  margin: 0 auto;
  margin-top: 20px;
}
</style>