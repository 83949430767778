import axios from 'axios';
import qs from 'qs'
let urls=process.env.VUE_APP_URL

// axios.defaults.headers.common['Authorization'] =sessionStorage.getItem('token')
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
function httpget(option){
     return new Promise((rev,rej)=>{
       axios.interceptors.request.use((req)=>{
         let token=sessionStorage.getItem('token')
         req.headers['token'] =  token// token
        
         return req

       })
        axios({
            url: urls+option.url,
            method: option.method,
            data: option.data
          })
          .then(res=>{
            console.log(res);
            if(res.data.code==412){
              this.$message.success(res.data.msg);
              this.$router.push('/')

            }
              if(res.status == '200'){
                rev(res)
                if(option.url == '/api/Luggage/updLuggage'
                 || option.url == '/api/Luggage/updLuggage' || 
                 option.url == '/api/Expressage/updExpressage' ||
                 option.url == '/api/Expressage/upExpressage'){
                  return false
                }else if(option.url == '/api/Login/login'){
                  this.$message.success(res.data.msg);
                }
              }else{
                // this.$message.error(res.data.msg);
              }
              
          })
          .catch(err=>{
              // console.log(res);
              // this.$message.error(res.data.message);
              rej(err)
          })
    })
}

// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}

export { httpget }