<template>
  <div>
    <div>
      <a-row class="top"
        ><a-col :span="4" class="text" style="width: 20%">
          订单管理/行李寄存订单列表
        </a-col>
        <a-col :span="2">
          <a-button @click="exportt">导出</a-button>
        </a-col></a-row
      >
      <a-row class="top">
        <a-col :span="2" class="top-l">
          <a-input placeholder="寄存人姓名" v-model="lists.name" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="房间号" v-model="lists.room" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="手机号" v-model="lists.tel" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-date-picker
            style="width: 80%"
            :value="lists.time"
            @change="onChange"
            placeholder="选择日期"
          >
          </a-date-picker>
        </a-col>
        <a-col :span="2" class="top-l">
          <a-select
            placeholder="超时物品"
            allowClear
            :value="lists.overtime"
            style="width: 100%"
            @change="ChangeTime"
          >
            <a-select-option
              :value="index"
              v-for="(item, index) in overtime"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2" class="top-l">
          <a-select
            placeholder="寄存状态"
            allowClear
            :value="lists.state"
            style="width: 100%"
            @change="ChangeState"
          >
            <a-select-option
              :value="index"
              v-for="(item, index) in xzstate"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2" class="top-l">
          <a-input placeholder="订单编号" v-model="lists.num" />
        </a-col>
        <a-col :span="2" class="top-l">
          <a-select
            placeholder="酒店"
            :allowClear="true"
            :value="lists.hotelName"
            style="width: 100%"
            @change="ChangeHotelName"
            show-search
          >
            <a-select-option
              :value="item"
              v-for="(item, index) in hotelName_list"
              :key="item.id"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="1" class="top-l">
          <a-button @click="about">查询</a-button>
        </a-col>
        <a-col :span="1" class="top-rr">
          <a-button @click="result">重置</a-button>
        </a-col>
      </a-row>
      <div class="top" style="height: 70vh; overflow: auto">
        <a-table
          bordered
          :loading="loading"
          :columns="columns"
          :data-source="data"
          :scroll="{ x: 1500 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="state" slot-scope="record">
            {{ record.state | stateval }}
          </template>
          <template slot="timeout" slot-scope="record">
            {{ record | timeout }}
          </template>
          <template slot="creat" slot-scope="record">
            {{ record.createDate }}
          </template>
          <template slot="type" slot-scope="record">
            {{ record.type | typeval }}
          </template>
          <template slot="way" slot-scope="record">
            {{ record.qj | wayval }}
          </template>
          <template slot="pickupRemark" slot-scope="record">
            <span style="color: #2680EB;cursor:pointer" @click="addDeta(record.id)">详情</span>
          </template>
          <template slot="qjUpdatetime" v-if="record.state == 2" slot-scope="record">
            {{record.qj[0].update_time}}
          </template>
        </a-table>
      </div>
    </div>
	<a-modal v-model="detaShow" title="详情" @ok="detaShow = false" okText="我知道了">
	  <div v-for="(item, index) in Info" :key="index">
	    <p>修改人：{{ item.username }}</p>
	    <p>内容：{{ item.content }}</p>
	    <p>修改时间：{{ item.create_time }}</p>
	  </div>
    <a-input placeholder="新纪录" v-model="jilu" />
    <a-button style="margin-top:20px" @click="addLog">添加记录</a-button>
	</a-modal>
  </div>
</template>
<script>
import download from "@/http/download";
export default {
  data() {
    return {
      overtime: ["未超时", "已超时"],
      xzstate: ["待寄存", "寄存中", "已完成", "已取消"],
      hotelName_list: [],
      loading: false,
      lists: {
        name: "",
        room: "",
        time: "",
        overtime: undefined,
        state: undefined,
        num: "",
        hotelName: undefined,
        tel: "",
      },
      strt: "",
      columns: [
        {
          width: 100,
          title: "订单编号",
          dataIndex: "id",
        },
        {
          width: 150,
          title: "创建时间",
          dataIndex: "createDate",
        },
        {
          width: 120,
          title: "寄存酒店",
          dataIndex: "hotelName",
        },
        {
          width: 80,
          title: "房间号",
          dataIndex: "homeNum",
        },
        {
          width: 100,
          title: "号牌",
          dataIndex: "qj[0].mark",
        },
        {
          width: 150,
          title: "寄存时间",
          scopedSlots: {
            customRender: "creat",
          },
        },
        {
          width: 90,
          title: "寄存状态",
          scopedSlots: {
            customRender: "state",
          },
        },
        {
          width: 130,
          title: "寄存方式",
          scopedSlots: {
            customRender: "type",
          },
        },
        {
          width: 150,
          title: "存件备注",
          dataIndex: "remark",
        },
        {
          width: 90,
          title: "物品超时",
          scopedSlots: {
            customRender: "timeout",
          },
        },
        {
          title: "取件人姓名",
          dataIndex: "qj[0].qjName",
          width: 110,
        },
        {
          title: "取件人手机号",
          dataIndex: "qj[0].qjPhone",
          width: 120,
        },
        {
          width: 180,
          title: "取件人信息",
          dataIndex: "identity",
        },
        {
          width: 170,
          title: "取件时间",
          scopedSlots: {
            customRender: "qjUpdatetime",
          },
        },
        {
          width: 150,
          title: "取件备注",
         scopedSlots: { customRender: "pickupRemark" },
        },
        {
          width: 100,
          fixed: "right",
          title: "取件方式",
          scopedSlots: {
            customRender: "way",
          },
        },
        {
          width: 120,
          fixed: "right",
          title: "存件人姓名",
          dataIndex: "userName",
        },
        {
          width: 120,
          fixed: "right",
          title: "存件人手机号",
          dataIndex: "phone",
        },
      ],
      data: [],
      detaShow:false,
      Info:[],
      jilu:'',
      jiluID:''
    };
  },
  mounted() {
    this.defaultList();
    this.get_hotelName_list();
  },
  methods: {
    addLog() {
      let option = {
        url: `/api/luggage/addPartLog?id=${this.jiluID}&type=1&content=${this.jilu}`,
        method: "get",
      };
      this.$http(option).then((res) => {
        this.$message.success(res.data.msg);
        // 刷新
        this.addDeta(this.jiluID)
      });
      // 清空
      this.jilu = ''
    },
    addDeta(id) {
      this.jiluID = id
      let option = {
        url: `/api/luggage/partLogList?id=${id}&type=1`,
        method: "get",
      };
      this.$http(option).then((res) => {
        // console.log(res)
        this.detaShow = true;
        this.Info = res.data.data.info
      });
    },
    defaultList() {
      this.loading = true;
      let option = {
        url: "/api/" + "Luggage" + "/" + "getLuggageList",
        method: "post",
        data: {
          isCare: 0,
          userName: this.lists.name,
          homeNum: this.lists.room,
          createDate: this.strt,
          timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
          state: this.lists.state == undefined ? "" : this.lists.state,
          hotelName:
            this.lists.hotelName == undefined ? "" : this.lists.hotelName,
          id: this.lists.num,
          mobile: this.lists.tel,
        },
      };
      this.$http(option).then((res) => {
        this.loading = false;
        this.data = res.data.data.lists;
      });
    },
    get_hotelName_list() {
      let option = {
        url: "/api/" + "Hotel" + "/" + "getHotelsName",
        method: "post",
        data: {},
      };
      this.$http(option).then((res) => {
        this.hotelName_list = res.data.data.lists;
      });
    },
    onChange(date, dateString) {
      this.strt = dateString;
      this.lists.time = date;
    },
    ChangeTime(value) {
      this.lists.overtime = value;
    },
    ChangeState(value) {
      this.lists.state = value;
    },
    ChangeHotelName(value) {
      // console.log(value);
      this.lists.hotelName = value;
    },
    edit(info) {
      // console.log(info);
    },
    about() {
      let option = {
        url: "/api/" + "Luggage" + "/" + "getLuggageList",
        method: "post",
        data: {
          isCare: 0,
          userName: this.lists.name,
          homeNum: this.lists.room,
          createDate: this.strt,
          timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
          state: this.lists.state == undefined ? "" : this.lists.state,
          hotelName:
            this.lists.hotelName == undefined ? "" : this.lists.hotelName,
          id: this.lists.num,
          mobile: this.lists.tel,
        },
      };
      this.$http(option).then((res) => {
        this.data = res.data.data.lists;
      });
    },
    result() {
      (this.lists.name = ""),
        (this.lists.room = ""),
        (this.lists.time = ""),
        (this.lists.overtime = undefined),
        (this.lists.state = undefined),
        (this.lists.num = ""),
        (this.strt = "");
      (this.lists.hotelName = ""), (this.lists.tel = "");
    },
    //导出
    exportt() {
      // const name = "be756b96-c8b5-46c4-ab67-02e988973090.xlsx";
      let name = {
        isCare: 0,
        userName: this.lists.name,
        homeNum: this.lists.room,
        createDate: this.strt,
        timeout: this.lists.overtime == undefined ? "" : this.lists.overtime,
        state: this.lists.state == undefined ? "" : this.lists.state,
        hotelName:
          this.lists.hotelName == undefined ? "" : this.lists.hotelName,
        id: this.lists.num,
        mobile: this.lists.tel,
      };

      // 默认下载方法
      download.name(name, "api/luggage/export");
    },
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 100% !important;
}

.top-l {
  margin-left: 10px;
}

.top-e {
  margin-right: 10px;
}

.text {
  line-height: 32px;
}

.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}

.but:hover {
  cursor: pointer;
}

.edd {
  display: flex;
  justify-content: space-between;
}

.edd div:hover {
  cursor: pointer;
}
</style>