<template>
  <div>
    <div class="top" style="height:70vh;overflow: auto;">
      <a-row class="top">
        <a-col :span="3" class="text"> 短信管理/充值列表 </a-col>
        <!-- <a-col :span="3" class="top-l">
          <a-input placeholder="寄存人姓名" v-model="text"/>
        </a-col> -->
        <a-col :span="3" class="top-l">
          <a-date-picker style="width: 100%" :value="showDateTime" placeholder="选择日期" @change="onChange"  />
        </a-col>
        <a-col :span="1" class="top-l"> <a-button @click="xc">查询</a-button> </a-col>
        <a-col :span="1" class="top-rr"> <a-button @click="result">重置</a-button> </a-col>
        <!-- <a-col :span="1" class="top-r"> <a-button>短信费用设置</a-button> </a-col> -->
      </a-row>
      <div class="top top-flex">
        <div>累计充值金额:{{ money.pileup/100 }}元</div>
        <div>微信充值金额:{{ money.wx/100 }}元</div>
        <div>支付宝充值金额:{{ money.zfb /100}}元</div>
        <div>剩余条数:{{ ts }}条</div>
      </div>
      <div class="top">
        <div class="but" @click="pay">短信充值</div>
      </div>
      <div class="top">
        <a-table
           bordered
           :loading="loading"
          :columns="columns"
          :data-source="data"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="ty" slot-scope="record">
             {{ record.type | aa }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDateTime:'',
      text:'',
      loading:false,
      money: {
        pileup: 0,
        wx: 0,
        zfb: 0,
        ts: 0,
        time:''
      },
      strt:'',
      columns: [
        {
          title: "序号",
          dataIndex: "id",
        },
        {
          title: "充值时间",
          dataIndex: "createDate",
        },
        {
          title: "充值方式",
          scopedSlots: { customRender: 'ty' }
        },
        {
          title: "充值条数",
          dataIndex: "number",
        },
        {
          title: "充值金额(元)",
          dataIndex: "money",
        }
      ],
      data: [],
      ts:''
    };
  },
  mounted() { 
     this.defa()
     this.defanum()
     this.tss()
  },
  methods: {
    tss(){
      let option = {
        url: "/api/" + "Hotel" + "/" + "getHotelById",
        method: "post",
        data:{
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
        }
      };
      this.$http(option).then(res=>{
        this.ts = res.data.data.lists[0].residue
      })
    },
    defa(){
      this.loading=true
      let option = {
        url: "/api/" + "Note" + "/" + "getNoteListss",
        method: "post",
        data:{
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
        }
      };
      this.$http(option).then((res) => {
         this.loading=false
        let date=res.data.data.lists  
      date.map(item=>{
       item.money=item.money/100;

     })
       this.data=date
      
      });
    },
    defanum(){
       let option = {
        url: "/api/" + "Note" + "/" + "getNoteMoneyss",
        method: "post",
        data:{
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
        }
      };
      this.$http(option).then((res) => {
        console.log(res)
         this.money.pileup = res.data.data.wx + res.data.data.zfb
         this.money.wx = res.data.data.wx
         this.money.zfb = res.data.data.zfb
         let info = JSON.parse(sessionStorage.getItem('info'))
         this.money.ts = info.number
      });
    },
    xc(){
      if(this.showDateTime){
         let option = {
        url: "/api/" + "Note" + "/" + "getNotessss",
        method: "post",
        data:{
          creatDate:this.strt,
          hotelId: JSON.parse(sessionStorage.getItem("info")).id,
        }
      };
      this.$http(option).then((res) => {
        // this.data = res.data.data.lists
         let date=res.data.data.lists  
      date.map(item=>{
       item.money=item.money/100;
       
     })
       this.data=date
      });
      }else{
        this.defa()
      }
      
    },
    onChange(moment,strtime){
      this.strt = strtime
      this.showDateTime = moment
    },
    edit(info) {
     
    },
    pay() {
      this.$router.push("/jdhome/jdpay");
    },
    result(){
      this.showDateTime = ''
    }
  },
};
</script>
<style scoped>
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -46%;
}
.top-flex {
  width: 75%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
</style>