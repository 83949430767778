<template>
  <div>
    <div class="top" style=" overflow: auto">
      <a-row class="top">
        <a-col :span="2" class="text" style="width: 10%"> 其他内容设置 </a-col>
        <!-- <a-col :span="1" class="top-backk"> <a-button>返回</a-button> </a-col> -->
      </a-row>
      <div class="top">规则设置</div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 84px"> 生成寄存单 </a-col>
          <a-col :span="3">
            <a-input v-model="form.hours" />
          </a-col>
          <a-col :span="5" class="note">
            &nbsp;&nbsp;小时不寄存，寄存单自动取消
          </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 84px"> 寄存 </a-col>
          <a-col :span="3">
            <a-input v-model="form.day" />
          </a-col>
          <a-col :span="3" class="note"> &nbsp;&nbsp;天寄存超时 </a-col>
        </a-row>
      </div>
      <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 84px"> 寄存协议 </a-col>
          <a-col :span="3">
            <a-textarea placeholder="" v-model="form.text" :rows="6" />
          </a-col>
           <a-col :span="3">
             <div style="color:red"> 提示：中文符；是换行符</div>
            
          </a-col>
        </a-row>
      </div>
      <!-- <div class="top">
        <a-row>
          <a-col :span="1" class="note" style="width: 84px"> 企业介绍 </a-col>
          <a-col :span="22">
            <quill-editor
              class="edit_container"
              ref="myQuillEditor"
              v-model="content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            />
          </a-col>
        </a-row>
      </div> -->
      <div class="top" style="margin-top: 70px">
        <a-button type="danger" @click="saveHtml"> 确认 </a-button>
      </div>
    </div>

    <div v-html="form.htmlcontent" id="dom" style="display: none"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hh:'',
      form: {
        hours: "",
        day: "",
        text: "",
        htmlcontent: "",
      },
      num: 0,
      setid: "",
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
          ],
        },
        placeholder: "请输入内容及工作量",
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    let option = {
      url: "/api/" + "Rule" + "/" + "getRule",
      method: "post",
    };
    this.$http(option).then((res) => {
      if (res.data.data.lists.length == 0) {
        this.num = 0;
      } else {
        this.form.hours = res.data.data.lists[0].deposit;
        this.form.day = res.data.data.lists[0].day;
        this.form.text = res.data.data.lists[0].protocol;
        this.content = res.data.data.lists[0].content;
        this.content =  this.content.replace(/\n/g, '<br>')
        sessionStorage.setItem("qitaid", res.data.data.lists[0].id)
        this.num = 1;
      }
    });
  },
  methods: {
    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur(e) {},
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(e) {
      // console.log(e.text)
      this.hh = e.text
      this.form.htmlcontent = this.content;
    }, // 内容改变事件
    saveHtml: function (event) {
      console.log()
      // var dom = document.getElementById("dom").getElementsByTagName("p");
      if (
        this.form.hours &&
        this.form.day &&
        this.form.text 
        
      ) {
        if (this.num == 0) {
          let option = {
            url: "/api/" + "Rule" + "/" + "setRule",
            method: "post",
            data: {
              deposit: this.form.hours,
              day: this.form.day,
              protocol: this.form.text,
              content: this.hh,
            },
          };
          this.$http(option).then((res) => {
            if (res.status == "200") {
               this.$message.success("保存成功");
            }
          });
        } else {
          let option = {
            url: "/api/" + "Rule" + "/" + "updateRule",
            method: "post",
            data: {
              deposit: this.form.hours,
              day: this.form.day,
              protocol: this.form.text,
              content: this.hh,
              id: sessionStorage.getItem("qitaid"),
            },
          };
          this.$http(option).then((res) => {
            if (res.status == "200") {
              this.$message.success("保存成功");
            }
          });
        }
      } else {
        this.$message.warning("请认真填写");
      }
    },
  },
};
</script>
<style scoped>
.note {
  line-height: 32px;
}
.top {
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.top-l {
  margin-left: 20px;
}
.top-e {
  margin-right: 10px;
}
.text {
  line-height: 32px;
}
.but {
  width: 98px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: rgba(230, 182, 102, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
}
.but:hover {
  cursor: pointer;
}
.edd {
  display: flex;
  justify-content: space-between;
}
.edd div:hover {
  cursor: pointer;
}
.top-r {
  position: relative;
  right: -87%;
}
.top-flex {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 38px;
}
.edit_container {
  height: 200px;
}
</style>